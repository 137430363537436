import {Component, Input, IterableDiffers, KeyValueDiffers, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ClearingFileService} from 'src/app/digi-common/services/clearing-file.service';
import {CurrencyService} from 'src/app/digi-common/services/currency.service';
import {ClearingFileBehaviourSubject} from '../../../../../subjects/clearingfile-behaviour-subject';
import {SupplierInvoiceBehaviourSubject} from '../../../../../subjects/supplierInvoice-behaviour-subject';
import {TransportDocumentBehaviourSubject} from '../../../../../subjects/transport-document-behaviour-subject';
import {ClearingFile} from '../../../models/clearingFile';
import {Currency, SupplierInvoice, TransportDocument} from '../../../models/file';
import {ShareDataService} from '../../../services/share-data.service';
import {UniqueConsignmentReferenceService} from '../../../services/unique-consignment-reference.service';
import {UniqueConsignmentBehaviourSubject} from '../../../../../subjects/unique-consignment-reference-subject';
import {FileType} from '../../../models/enumerations';
import {ExportFileBehaviourSubject} from '../../../../../subjects/exportfile-behaviour-subject';
import {ExportFile} from '../../../models/exportFile';
import {ExportFileService} from '../../../services/export-file.service';
import {PartDetail} from '../../../models/partDetail';
import {CsvUploadService} from '../../../services/csv-upload.service';
import {SaveClearingFile} from '../../../saveClearingFile';
import {NotificationService} from '../../../services/notification.service';
import {ValidationService} from '../../../services/validation.service';

@Component({
  selector: 'digi-invoice-headers',
  templateUrl: './invoice-headers.component.html',
  styleUrls: ['./invoice-headers.component.css'],
})
export class InvoiceHeadersComponent implements OnInit, OnDestroy {
  @Input() public alerts: Array<string> = [];
  @Input() fileType: FileType = 'imports';
  selectedSupplierInvoice: SupplierInvoice;
  selectedTransportDocument: TransportDocument;
  file: ClearingFile|ExportFile;
  currencies = [];
  currency: Currency;
  calcLinesOnFiles: number;
  returned: number;
  calcTotalOnFiles = 0;
  display = false;
  subscription: Subscription;
  tdSubscription: Subscription;
  siSubscription: Subscription;
  displayUCRModal = false;
  filteredUCRs = [];
  roleType = '4';
  numOfInvoiceLines: number;
  validationMessagesForInvoiceNo: any[] = [];
  partDetails: PartDetail[];
  headings: any[];
  private csv: any;
  private uploadFile: any;
  showProgressBar: boolean;
  cols: any[];
  disableButton: boolean;

  constructor(
    private shareDataService: ShareDataService,
    private clearingFileService: ClearingFileService,
    private exportFileService: ExportFileService,
    private currencyService: CurrencyService,
    private fb: FormBuilder,
    private iterableDiffers: IterableDiffers,
    private keyValueDiffers: KeyValueDiffers,
    private transportDocumentBehaviourSubject: TransportDocumentBehaviourSubject,
    private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
    private exportFileBehaviourSubject: ExportFileBehaviourSubject,
    private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
    private uniqueConsignmentReferenceService: UniqueConsignmentReferenceService,
    private uniqueConsignmentBehaviourSubject: UniqueConsignmentBehaviourSubject,
    private csvUploadService: CsvUploadService,
    private saveClearingFile: SaveClearingFile,
    private notificationService: NotificationService,
    private validationService: ValidationService
  ) {
  }

  ngOnInit() {
    this.cols = [
      {field: 'partNumber', header: 'Part Number', width: '15%'},
      {field: 'description', header: 'Description', width: '20%'},
      {field: 'tariffCode', header: 'Tariff Code', width: '15%'},
      {field: 'invoiceValue', header: 'Invoice Value', width: '20%'},
      {field: 'weight', header: 'Weight', width: '20%'}
    ];
    if (this.fileType === 'imports') {
      this.subscription = this.clearingFileBehaviourSubject.getClearingFile()
        .subscribe(cf => {
          this.file = cf;
          if (this.tdSubscription) {
            this.tdSubscription.unsubscribe();
          }
          this.tdSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
            .subscribe(td => {
              this.selectedTransportDocument = td;
              if (this.selectedTransportDocument) {
                if (this.selectedTransportDocument.supplierInvoices.length === 0) {
                  const supplierInvoice: SupplierInvoice = this.shareDataService.addSupplierInvoice(this.selectedTransportDocument);
                  this.selectedTransportDocument.supplierInvoices.push(supplierInvoice);
                  this.selectedTransportDocument.supplierInvoices = [...this.selectedTransportDocument.supplierInvoices];
                  this.supplierInvoiceBehaviourSubject.addSupplierInvoice(supplierInvoice);
                } else {
                  this.supplierInvoiceBehaviourSubject.addSupplierInvoice(this.selectedTransportDocument.supplierInvoices[0]);
                }
              }
            });
          if (this.siSubscription) {
            this.siSubscription.unsubscribe();
          }
          this.siSubscription = this.supplierInvoiceBehaviourSubject
            .getSupplierInvoice()
            .subscribe(si => {
              if (si !== null && si !== undefined) {
                this.selectedSupplierInvoice = si;
                this.calculateLinesOnFile(this.fileType);
                this.calculateTotalOnFile();
                if (this.selectedSupplierInvoice.lines) {
                  this.numOfInvoiceLines = this.selectedSupplierInvoice.lines.length;
                }
                if (this.selectedSupplierInvoice.ucrNumber) {
                  const code = this.fileType === 'imports' ? this.file.localAgent.code : this.file.exporter.code;
                  this.uniqueConsignmentReferenceService.findUCRWithNumberAndCodeAndRoleType(this.selectedSupplierInvoice.ucrNumber, code, this.roleType).subscribe(data => {
                      this.selectedSupplierInvoice.ucr = data[0];
                    }
                  );
                  this.uniqueConsignmentBehaviourSubject.addUniqueConsignmentReference(this.selectedSupplierInvoice.ucr);
                } else {
                  this.uniqueConsignmentBehaviourSubject.addUniqueConsignmentReference(null);
                }
              }
            });
        });
    } else {
      this.roleType = '12';
      this.subscription = this.exportFileBehaviourSubject.getExportFile()
        .subscribe(ef => {
          this.file = ef;
          if (this.tdSubscription) {
            this.tdSubscription.unsubscribe();
          }
          this.tdSubscription = this.transportDocumentBehaviourSubject.getTransportDocuments()
            .subscribe(td => {
              this.selectedTransportDocument = td;
              if (this.selectedTransportDocument) {
                if (this.selectedTransportDocument.supplierInvoices.length === 0) {
                  const supplierInvoice: SupplierInvoice = this.shareDataService.addSupplierInvoice(this.selectedTransportDocument);
                  this.selectedTransportDocument.supplierInvoices.push(supplierInvoice);
                  this.selectedTransportDocument.supplierInvoices = [...this.selectedTransportDocument.supplierInvoices];
                  this.supplierInvoiceBehaviourSubject.addSupplierInvoice(supplierInvoice);
                } else {
                  this.supplierInvoiceBehaviourSubject.addSupplierInvoice(this.selectedTransportDocument.supplierInvoices[0]);
                }
              }
            });
          if (this.siSubscription) {
            this.siSubscription.unsubscribe();
          }
          this.siSubscription = this.supplierInvoiceBehaviourSubject
            .getSupplierInvoice()
            .subscribe(si => {
              if (si !== null && si !== undefined) {
                this.selectedSupplierInvoice = si;
                this.calculateLinesOnFile(this.fileType);
                this.calculateTotalOnFile();
              }
              if (this.selectedSupplierInvoice.ucrNumber) {
                const code = this.fileType === 'imports' ? this.file.localAgent.code : this.file.exporter.code;
                this.uniqueConsignmentReferenceService.findUCRWithNumberAndCodeAndRoleType(this.selectedSupplierInvoice.ucrNumber, code, this.roleType).subscribe(data => {
                    this.selectedSupplierInvoice.ucr = data[0];
                  }
                );
                this.uniqueConsignmentBehaviourSubject.addUniqueConsignmentReference(this.selectedSupplierInvoice.ucr);
              } else {
                this.uniqueConsignmentBehaviourSubject.addUniqueConsignmentReference(null);
              }
            });
        });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.tdSubscription.unsubscribe();
    this.siSubscription.unsubscribe();
  }

  hasSupplierInvoice() {
    return this.selectedTransportDocument && this.selectedTransportDocument.supplierInvoices
      && this.selectedTransportDocument.supplierInvoices.length > 0;
  }

  isWarehouseImport() {
    return this.file && this.fileType === 'imports' && this.file.clearingInstructions[0].customsPurpose.customsPurposeCode.trim() === 'WH' || this.file.clearingInstructions[0].customsPurpose.customsPurposeCode.trim() === 'WE';
  }

  selectFiles(event: any) {
    const files = event.originalEvent.srcElement.files;
    this.uploadFile = files[0];
  }

  uploadManifest() {
    this.showProgressBar = true;
    if (this.file && this.file.id) {
      this.clearingFileService.uploadFromSpreadsheet(this.file.id, this.uploadFile).subscribe(
        file => {
          this.clearingFileBehaviourSubject.addClearingFile(file);
          this.showProgressBar = false;
        }
      );
    }
  }

  addSupplierInvoice() {
    const supplierInvoice: SupplierInvoice = this.shareDataService.addSupplierInvoice(this.selectedTransportDocument);
    this.selectedTransportDocument.supplierInvoices.push(supplierInvoice);
    this.selectedTransportDocument.supplierInvoices = [...this.selectedTransportDocument.supplierInvoices];
    this.supplierInvoiceBehaviourSubject.addSupplierInvoice(supplierInvoice);
    this.uniqueConsignmentBehaviourSubject.addUniqueConsignmentReference(null);
  }

  invoiceGrossValueChanged(value) {
    this.selectedSupplierInvoice.invoiceGrossValue = value;
    if (this.selectedSupplierInvoice.invoiceGrossValue) {
      this.selectedSupplierInvoice.lineWeight = (this.selectedSupplierInvoice.quantity /
        this.selectedSupplierInvoice.totalGrossIncomeValue) *
        this.selectedSupplierInvoice.invoiceGrossValue;
    }
  }

  calculateLinesOnFile(fileType: FileType) {
    let sum = 0;
    if (fileType === 'imports') {
      this.file.transportDocuments.forEach(t => {
        t.supplierInvoices.forEach(s => {
          sum += s.lines.length;
        });
      });
    } else {
      this.file.transportDocument.supplierInvoices.forEach(s => {
        sum += s.lines.length;
      });
    }
    this.calcLinesOnFiles = sum;
  }

  calculateTotalOnFile() {
    this.returned = this.shareDataService.calcTotalOnFiles(this.file, this.fileType);
    this.calcTotalOnFiles = +this.returned;
  }

  openSundryChargeModel() {
    this.display = true;
  }

  onDialogClose(event) {
    this.display = event;
  }

  openUCRModel() {
    this.displayUCRModal = true;
  }

  searchUniqueConsignmentReference(event) {
    const code = this.fileType === 'imports' ? this.file.localAgent.code : this.file.exporter.code;
    this.uniqueConsignmentReferenceService.findUCRWithNumberAndCodeAndRoleType(event.query, code, this.roleType).subscribe(data => {
      this.filteredUCRs = data;
    });
  }

  selectUCR(event) {
    this.selectedSupplierInvoice.ucrNumber = event.number;
    this.selectedSupplierInvoice.ucrCreditTerms = event.paymentTerm.code;
    this.selectedSupplierInvoice.ucr = event;
    this.copyUCRToLines();
  }

  onUCRCreated(event) {
    this.displayUCRModal = false;
    if (event && event.number) {
      this.selectedSupplierInvoice.ucr = event;
      this.selectedSupplierInvoice.ucrNumber = event.number;
      this.selectedSupplierInvoice.ucrCreditTerms = event.paymentTerm.code;
      this.copyUCRToLines();
    }
  }

  copyUCRToLines() {
    this.selectedSupplierInvoice.lines.forEach(line => {
      line.ucrNumber = this.selectedSupplierInvoice.ucrNumber;
      line.ucr = this.selectedSupplierInvoice.ucr;
      if (this.selectedSupplierInvoice.ucr) {
        line.ucrTransactionValue = this.selectedSupplierInvoice.ucr.transactionValue;
        line.ucrTransactionValueCurrency = this.selectedSupplierInvoice.ucr.transactionCurrency.code;
        line.ucrCreditTerms = this.selectedSupplierInvoice.ucr.paymentTerm.code;
      }
    });
  }

  validateInvoiceNumber() {
    if (this.fileType === 'imports') {
      this.clearingFileService.findClearingFileNumbersByInvoiceNo(this.selectedSupplierInvoice.invoiceNumber, this.file.clearingFileNumber).subscribe(
        res => {
          if (res.length > 0) {
            const message = 'Invoice No. used in [' + res.join(', ') + ']';
            const foundDetail = this.validationMessagesForInvoiceNo.find(el => el.detail === message);
            if (!foundDetail) {
              this.validationMessagesForInvoiceNo.push({severity: 'warn', summary: 'Duplicate Invoice No.', detail: message});
            }
          } else if (this.selectedTransportDocument.supplierInvoices.filter(inv => inv.invoiceNumber === this.selectedSupplierInvoice.invoiceNumber).length > 1) {
            this.validationMessagesForInvoiceNo.push({severity: 'warn', summary: 'Duplicate Invoice No.', detail: 'Invoice Number: '.concat(this.selectedSupplierInvoice.invoiceNumber).concat(' is duplicated in the current file.')});
          } else {
            this.validationMessagesForInvoiceNo.length = 0;
          }
        },
        _ => this.validationMessagesForInvoiceNo.length = 0
      );
    } else {
      this.exportFileService.findClearingFileNumbersByInvoiceNo(this.selectedSupplierInvoice.invoiceNumber, this.file.clearingFileNumber).subscribe(
        res => {
          if (res.length > 0) {
            const message = `Invoice No (${this.selectedSupplierInvoice.invoiceNumber}). used in [` + res.join(', ') + ']';
            const foundDetail = this.validationMessagesForInvoiceNo.find(el => el.detail === message);
            if (!foundDetail) {
              this.validationMessagesForInvoiceNo.push({severity: 'warn', summary: 'Duplicate Invoice No.', detail: message});
            }
          } else if (this.selectedTransportDocument.supplierInvoices.filter(inv => inv.invoiceNumber === this.selectedSupplierInvoice.invoiceNumber).length > 1) {
            this.validationMessagesForInvoiceNo.push({severity: 'warn', summary: 'Duplicate Invoice No.', detail: 'Invoice Number: '.concat(this.selectedSupplierInvoice.invoiceNumber).concat(' is duplicated in the current file.')});
          } else {
            this.validationMessagesForInvoiceNo.length = 0;
          }
        },
        _ => this.validationMessagesForInvoiceNo.length = 0
      );
    }
  }

  selectFile(event: any) {
    const files = event.originalEvent.srcElement.files;
    this.csv = files[0];
    this.csvUploadService.createPartDetails(this.csv, 'invoiceLine', this.fileType === 'imports' ? 'I' : 'E').subscribe(
      data => this.partDetails = data
    );
  }

  uploadPartDetails() {
    this.disableButton = true;
    if (this.fileType === 'imports') {
      this.clearingFileService.createInvoiceLines(this.partDetails, this.file.id, this.selectedTransportDocument.mawbNo, this.selectedSupplierInvoice.invoiceNumber).subscribe(
        data => {
          this.disableButton = false;
          this.notificationService.successNotify('Invoice Lines Created Successfully');
          this.saveClearingFile.updateClearingFileAfterSaving(data, this.file, this.selectedTransportDocument, this.selectedSupplierInvoice, null);
        },
        _ => this.disableButton = false
      );
    } else {
      this.exportFileService.createInvoiceLines(this.partDetails, this.file.id, this.selectedTransportDocument.mawbNo, this.selectedSupplierInvoice.invoiceNumber).subscribe(
        data => {
          this.disableButton = false;
          this.notificationService.successNotify('Invoice Lines Created Successfully');
          this.exportFileService.updateFileAfterSaving(data, this.selectedTransportDocument, this.selectedSupplierInvoice, null);
        },
        _ => this.disableButton = false
      );
    }
  }

  deletePartDetail(index: any) {
    this.partDetails.splice(index, 1);
    this.partDetails = [...this.partDetails];
  }

  handleIndexChange(index: number) {
    if (index === 1 || index === 2) {
      const failed = this.validationService.validateFileBasedOnStep('supplierInvoice', this.file, this.fileType,
        (header: string, messages: string[]) => this.showErrors(header, messages));

      if (!failed) {
        if (this.fileType === 'imports') {
          this.saveClearingFile.saveClearingFile(this.file, this.selectedTransportDocument, this.selectedSupplierInvoice, null).subscribe(
            _ => this.notificationService.successNotify('File Saved Successfully')
          );
        } else {
          this.exportFileService.saveExportsFile(this.file, this.selectedTransportDocument, this.selectedSupplierInvoice, null).subscribe(
            _ => this.notificationService.successNotify('File Saved Successfully')
          );
        }
      }
    }
  }

  showErrors(label: string, messages?: string[]) {
    if (messages) {
      this.notificationService.errorNotify(label, messages);
    } else {
      this.notificationService.errorNotify(label);
    }
    return true;
  }

  clearUcr() {
    this.selectedSupplierInvoice.ucr = null;
    this.selectedSupplierInvoice.ucrNumber = null;
    this.selectedSupplierInvoice.ucrCreditTerms = null;
    this.supplierInvoiceBehaviourSubject.addSupplierInvoice(this.selectedSupplierInvoice);
    this.copyUCRToLines();
  }
}
