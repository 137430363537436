import {
  Airline,
  CustomOffice,
  Debtor,
  ExportTransportDocument,
  LocalAgent,
  LocalForwarder,
  Measures, PortDetail,
  Reference,
  Supplier,
  Warehouse
} from './file';
import {Importer} from './clearingFile';
import {BillOfEntryMarksAndNumbers, EDIMessage} from './billOfEntries';
import {CuscarType} from './roadManifest';

export class TransShipmentDetails {
  feederVessel?: string;
  feederVoyageNo?: string;
  transferPort?: PortDetail;
  departureDate?: string;
  arrivalDate?: string;
}

export class ForwardingTransportDocument extends ExportTransportDocument {
  masterAirline?: Airline;
  releaseAgent?: LocalForwarder;
  measures?: MasterBillMeasures;
  importer?: Importer;
  supplier?: Supplier;
  debtor?: Debtor;
  localAgent?: LocalAgent;
  marksNumbersAndEndorsement?: BillOfEntryMarksAndNumbers[];
  runningTotals?: RunningTotals;
  transShipmentDetails?: TransShipmentDetails;
  trackingDetails?: TrackingDetails;
}

export class TrackingDetails {
  submissionDate?: string;
  releaseDate?: string;
  receivedAtORTIA?: string;
  receivedAtJNBOPS?: string;
  podDate?: string;
  comment?: string;
  hawbAndDetails?: string;
  statusPoBoxTimestamp?: string;
  statusImporterCodeTimestamp?: string;
  statusDutyConfTimestamp?: string;
  statusCustomsStopTimestamp?: string;
  statusOagStopTimestamp?: string;
  statusMissingDocsTimestamp?: string;
  statusGeneralQueryTimestamp?: string;
  generalQuery?: string;
}

export class MasterBillMeasures extends Measures {
  numberOfHawbs?: number;
}

export class RunningTotals {
  remainingNoOfPacks?: number;
  capturedNoOfPacks?: number;
  remainingNoOfHouseBills?: number;
  capturedNoOfHouseBills?: number;
  remainingWeight?: number;
  capturedWeight?: number;
  remainingChargeableWeight?: number;
  capturedChargeableWeight?: number;
}

export class ForwardingFile {
  fileNumber?: string;
  id?: string;
  transportMethodCode?: string;
  masterBill?: ForwardingTransportDocument;
  customOffice?: CustomOffice;
  reference?: Reference;
  warehouse?: Warehouse;
  houseBills?: ForwardingTransportDocument[];
  contrl?: EDIMessage;
  cusres?: EDIMessage;
  cuscars?: string[];
  cuscarType?: CuscarType;
  sequence?: number;
  manifestType?: string;
  branch?: string;
  manifestAmendmentOrCancellation?: string;

  [k: string]: any;
}
