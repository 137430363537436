import { PreviousBoeDetails, SupplierInvoiceLine } from '../../../models/file';
import { OnDestroy, OnInit } from '@angular/core';
import { ShareDataService } from '../../../services/share-data.service';
import { SupplierInvoiceLineBehaviourSubject } from '../../../../../subjects/supplier-invoice-line-behaviour-subject.service';
import { SupplierInvoiceBehaviourSubject } from '../../../../../subjects/supplierInvoice-behaviour-subject';
import { ClearingFileBehaviourSubject } from '../../../../../subjects/clearingfile-behaviour-subject';
import { ExportFileBehaviourSubject } from '../../../../../subjects/exportfile-behaviour-subject';
import { UniqueConsignmentBehaviourSubject } from '../../../../../subjects/unique-consignment-reference-subject';
import { TransportDocumentBehaviourSubject } from '../../../../../subjects/transport-document-behaviour-subject';
import { ValidationService } from '../../../services/validation.service';
import { NotificationService } from '../../../services/notification.service';
import { SaveClearingFile } from '../../../saveClearingFile';
import { ExportFileService } from '../../../services/export-file.service';
import { BillOfEntryService } from '../../../services/bill-of-entry.service';
var InvoiceLinesComponent = /** @class */ (function () {
    function InvoiceLinesComponent(shareDataService, supplierInvoiceLineBehaviourSubjectComponent, clearingFileBehaviourSubject, supplierInvoiceBehaviourSubject, exportFileBehaviourSubject, uniqueConsignmentBehaviourSubject, transportDocumentBehaviorSubject, validationService, notificationService, saveClearingFile, exportFileService, billOfEntryService) {
        this.shareDataService = shareDataService;
        this.supplierInvoiceLineBehaviourSubjectComponent = supplierInvoiceLineBehaviourSubjectComponent;
        this.clearingFileBehaviourSubject = clearingFileBehaviourSubject;
        this.supplierInvoiceBehaviourSubject = supplierInvoiceBehaviourSubject;
        this.exportFileBehaviourSubject = exportFileBehaviourSubject;
        this.uniqueConsignmentBehaviourSubject = uniqueConsignmentBehaviourSubject;
        this.transportDocumentBehaviorSubject = transportDocumentBehaviorSubject;
        this.validationService = validationService;
        this.notificationService = notificationService;
        this.saveClearingFile = saveClearingFile;
        this.exportFileService = exportFileService;
        this.billOfEntryService = billOfEntryService;
        this.display = false;
    }
    InvoiceLinesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.cols = [
            { field: 'lineNo', header: 'Line', width: '11%' },
            { field: 'code', header: 'Tarrif' },
            { field: 'invoiceGrossValue', header: 'Value' },
        ];
        this.boeLinesCols = [
            {
                width: '5%'
            },
            {
                header: 'Line No',
                width: '5%'
            },
            {
                header: 'MRN',
                width: '15%'
            },
            {
                header: 'CPC',
                width: '5%'
            },
            {
                header: 'Customs Date',
                width: '10%'
            },
            {
                header: 'Part Number',
                width: '15%'
            },
            {
                header: 'Available Packs',
                width: '10%'
            },
            {
                header: 'Selected Packs',
                width: '10%'
            },
            {
                header: 'Pack Type',
                width: '10%'
            },
            {
                header: 'Value',
                width: '10%'
            },
            {
                header: 'Qty',
                width: '5%'
            }
        ];
        if (this.fileType === 'imports') {
            this.subscription = this.clearingFileBehaviourSubject.getClearingFile()
                .subscribe(function (cf) {
                _this.file = cf;
                if (_this.siSubscription) {
                    _this.siSubscription.unsubscribe();
                }
                _this.siSubscription = _this.supplierInvoiceBehaviourSubject
                    .getSupplierInvoice()
                    .subscribe(function (si) {
                    _this.selectedSupplierInvoice = si;
                    if (!_this.selectedSupplierInvoice.lines) {
                        _this.selectedSupplierInvoice.lines = [];
                    }
                    if (!_this.selectedSupplierInvoice.lines.length) {
                        var createSupplierInvoiceLine = new SupplierInvoiceLine();
                        _this.selectedInvoiceLine =
                            _this.shareDataService.addSupplierInvoiceLine(_this.selectedSupplierInvoice, createSupplierInvoiceLine);
                        _this.selectedInvoiceLine.ucrNumber = _this.selectedSupplierInvoice.ucrNumber;
                        if (_this.selectedSupplierInvoice.ucr) {
                            _this.selectedInvoiceLine.ucrCreditTerms = _this.selectedSupplierInvoice.ucr.paymentTerm.code;
                            _this.selectedInvoiceLine.ucrTransactionValue = _this.selectedSupplierInvoice.ucr.transactionValue;
                            _this.selectedInvoiceLine.ucrTransactionValueCurrency = _this.selectedSupplierInvoice.ucr.transactionCurrency.code;
                        }
                        _this.selectedSupplierInvoice.lines.push(_this.selectedInvoiceLine);
                        _this.selectedSupplierInvoice.lines = _this.selectedSupplierInvoice.lines.slice();
                        _this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(_this.selectedInvoiceLine);
                    }
                    else {
                        _this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(_this.selectedSupplierInvoice.lines[0]);
                    }
                });
                if (_this.lineSubscription) {
                    _this.lineSubscription.unsubscribe();
                }
                _this.lineSubscription = _this.supplierInvoiceLineBehaviourSubjectComponent
                    .getSupplierInvoiceLine()
                    .subscribe(function (line) {
                    if (line) {
                        if (!line.costingUom) {
                            line.costingUom = Object.assign({}, _this.file.importer.costingUom);
                        }
                        _this.selectedInvoiceLine = _this.selectedSupplierInvoice.lines.find(function (l) { return l.lineNo === line.lineNo; });
                        if (_this.selectedInvoiceLine.instructionIndex) {
                            _this.clearingInstruction = _this.file.clearingInstructions[_this.selectedInvoiceLine.instructionIndex];
                            _this.populateClearingInstructionSelection();
                        }
                        else {
                            _this.setCustomsPurposeAndClearingInstruction(_this.selectedInvoiceLine);
                        }
                    }
                });
            });
        }
        else {
            this.subscription = this.exportFileBehaviourSubject.getExportFile()
                .subscribe(function (ef) {
                _this.file = ef;
                if (_this.siSubscription) {
                    _this.siSubscription.unsubscribe();
                }
                _this.siSubscription = _this.supplierInvoiceBehaviourSubject
                    .getSupplierInvoice()
                    .subscribe(function (si) {
                    _this.selectedSupplierInvoice = si;
                    if (!_this.selectedSupplierInvoice.lines) {
                        _this.selectedSupplierInvoice.lines = [];
                    }
                    if (!_this.selectedSupplierInvoice.lines.length) {
                        if (!_this.isXE() && !_this.isZE() || _this.selectedSupplierInvoice.manualExWarehouseLineCapture) {
                            var createSupplierInvoiceLine = new SupplierInvoiceLine();
                            var supplierInvoiceLine = _this.shareDataService.addSupplierInvoiceLine(_this.selectedSupplierInvoice, createSupplierInvoiceLine);
                            _this.selectedSupplierInvoice.lines.push(supplierInvoiceLine);
                            _this.selectedSupplierInvoice.lines = _this.selectedSupplierInvoice.lines.slice();
                            _this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(supplierInvoiceLine);
                        }
                    }
                    else {
                        _this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(_this.selectedSupplierInvoice.lines[0]);
                    }
                    _this.uniqueConsignmentBehaviourSubject.addUniqueConsignmentReference(_this.selectedSupplierInvoice.ucr);
                });
                if (_this.lineSubscription) {
                    _this.lineSubscription.unsubscribe();
                }
                _this.lineSubscription = _this.supplierInvoiceLineBehaviourSubjectComponent
                    .getSupplierInvoiceLine()
                    .subscribe(function (line) {
                    if (line) {
                        _this.selectedInvoiceLine = _this.selectedSupplierInvoice.lines.find(function (l) { return l.lineNo === line.lineNo; });
                        _this.selectedInvoiceLine.ucrNumber = _this.selectedSupplierInvoice.ucrNumber;
                        if (_this.selectedSupplierInvoice.ucr) {
                            _this.selectedInvoiceLine.ucrCreditTerms = _this.selectedSupplierInvoice.ucr.paymentTerm.code;
                            _this.selectedInvoiceLine.ucrTransactionValue = _this.selectedSupplierInvoice.ucr.transactionValue;
                            _this.selectedInvoiceLine.ucrTransactionValueCurrency = _this.selectedSupplierInvoice.ucr.transactionCurrency.code;
                        }
                        if (_this.selectedInvoiceLine.instructionIndex !== undefined && _this.selectedInvoiceLine.instructionIndex !== null) {
                            _this.clearingInstruction = _this.file.clearingInstructions[_this.selectedInvoiceLine.instructionIndex];
                            _this.populateClearingInstructionSelection();
                        }
                        else {
                            _this.setCustomsPurposeAndClearingInstruction(_this.selectedInvoiceLine);
                        }
                    }
                });
            });
        }
        this.transportDocumentSubscription = this.transportDocumentBehaviorSubject.getTransportDocuments().subscribe(function (td) {
            _this.transportDocument = td;
        });
    };
    InvoiceLinesComponent.prototype.hasSupplierInvoiceLine = function () {
        return this.selectedSupplierInvoice && this.selectedSupplierInvoice.lines && this.selectedSupplierInvoice.lines.length > 0;
    };
    InvoiceLinesComponent.prototype.handleManualExWarehouseCaptureChange = function (event) {
        if (!this.selectedSupplierInvoice.manualExWarehouseLineCapture) {
            this.selectedSupplierInvoice.lines = [];
            var createSupplierInvoiceLine = new SupplierInvoiceLine();
            this.selectedInvoiceLine =
                this.shareDataService.addSupplierInvoiceLine(this.selectedSupplierInvoice, createSupplierInvoiceLine);
            this.selectedInvoiceLine.ucrNumber = this.selectedSupplierInvoice.ucrNumber;
            if (this.selectedSupplierInvoice.ucr) {
                this.selectedInvoiceLine.ucrCreditTerms = this.selectedSupplierInvoice.ucr.paymentTerm.code;
                this.selectedInvoiceLine.ucrTransactionValue = this.selectedSupplierInvoice.ucr.transactionValue;
                this.selectedInvoiceLine.ucrTransactionValueCurrency = this.selectedSupplierInvoice.ucr.transactionCurrency.code;
            }
        }
        else if (!this.selectedInvoiceLine) {
            var createSupplierInvoiceLine = new SupplierInvoiceLine();
            var supplierInvoiceLine = this.shareDataService.addSupplierInvoiceLine(this.selectedSupplierInvoice, createSupplierInvoiceLine);
            this.selectedSupplierInvoice.lines.push(supplierInvoiceLine);
            this.selectedSupplierInvoice.lines = this.selectedSupplierInvoice.lines.slice();
            this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(supplierInvoiceLine);
        }
    };
    InvoiceLinesComponent.prototype.addInvoiceLines = function () {
        var _this = this;
        if (!this.isXE() && !this.isZE() || this.selectedSupplierInvoice.manualExWarehouseLineCapture) {
            this.disableButtons = true;
            var failed = this.validationService.validateFileBasedOnStep('supplierInvoice', this.file, this.fileType, function (header, messages) {
                _this.showErrors(header, messages);
                _this.disableButtons = false;
            });
            if (!failed) {
                if (this.fileType === 'imports') {
                    this.saveClearingFile.saveClearingFile(this.file, this.transportDocument, this.selectedSupplierInvoice, this.selectedInvoiceLine)
                        .subscribe(function (clearingFile) {
                        _this.file = clearingFile;
                        _this.notificationService.successNotify('File Saved Successfully');
                        _this.addLine();
                        _this.disableButtons = false;
                    }, function (_) { return _this.disableButtons = false; });
                }
                else {
                    this.exportFileService.saveExportsFile(this.file, this.transportDocument, this.selectedSupplierInvoice, this.selectedInvoiceLine)
                        .subscribe(function (exportFile) {
                        _this.file = exportFile;
                        _this.notificationService.successNotify('File Saved Successfully');
                        _this.addLine();
                        _this.disableButtons = false;
                    }, function (_) { return _this.disableButtons = false; });
                }
            }
        }
        else {
            this.showDialog = true;
            this.searchBoes();
        }
    };
    InvoiceLinesComponent.prototype.isXE = function () {
        return this.file && this.file.clearingInstructions[0].customsPurpose.customsPurposeCode.trim() === 'XE';
    };
    InvoiceLinesComponent.prototype.isZE = function () {
        return this.file && this.file.clearingInstructions[0].customsPurpose.customsPurposeCode.trim() === 'ZE';
    };
    InvoiceLinesComponent.prototype.addLine = function () {
        var createSupplierInvoiceLine = new SupplierInvoiceLine();
        this.setCustomsPurposeAndClearingInstruction(createSupplierInvoiceLine);
        var invoiceLine = this.shareDataService
            .addSupplierInvoiceLine(this.selectedSupplierInvoice, createSupplierInvoiceLine);
        this.selectedSupplierInvoice.lines.push(invoiceLine);
        this.selectedSupplierInvoice.lines = this.selectedSupplierInvoice.lines.slice();
        this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(invoiceLine);
    };
    InvoiceLinesComponent.prototype.searchCustomsPurpose = function (event) {
        var query = event.query;
        this.filteredCustomPurposes = this.file.clearingInstructions
            .filter(function (instruction) { return instruction.customsPurpose.customsPurposeName.toLowerCase().indexOf(query.toLowerCase()) === 0; });
    };
    InvoiceLinesComponent.prototype.selectCustomsPurpose = function (event) {
        this.selectedCustomsPurpose = Object.assign({}, event.customsPurpose);
        var index = this.file.clearingInstructions.indexOf(event);
        this.clearingInstruction = event;
        this.selectedInvoiceLine.instructionIndex = index;
        this.selectedInvoiceLine.instructionUUID = this.clearingInstruction.uuid;
    };
    InvoiceLinesComponent.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.siSubscription) {
            this.siSubscription.unsubscribe();
        }
        if (this.lineSubscription) {
            this.lineSubscription.unsubscribe();
        }
        if (this.transportDocumentSubscription) {
            this.transportDocumentSubscription.unsubscribe();
        }
    };
    InvoiceLinesComponent.prototype.setCustomsPurposeAndClearingInstruction = function (line) {
        line.instructionIndex = 0;
        this.clearingInstruction = this.file.clearingInstructions[0];
        line.instructionUUID = this.file.clearingInstructions[0].uuid;
        if (line.customsVAT === undefined) {
            line.customsVAT = this.clearingInstruction.customVatRequired;
        }
        this.populateClearingInstructionSelection();
    };
    InvoiceLinesComponent.prototype.populateClearingInstructionSelection = function () {
        this.selectedCustomsPurpose = this.clearingInstruction.customsPurpose;
    };
    InvoiceLinesComponent.prototype.openClearingInstructionModel = function () {
        this.display = true;
    };
    InvoiceLinesComponent.prototype.onDialogClose = function (event) {
        this.display = event;
    };
    InvoiceLinesComponent.prototype.selectInvoiceLine = function (value) {
        this.selectedInvoiceLine = value;
        this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(this.selectedInvoiceLine);
    };
    InvoiceLinesComponent.prototype.deleteLine = function (index) {
        this.selectedSupplierInvoice.lines.splice(index, 1);
        this.selectedSupplierInvoice.lines.forEach(function (supplierInvoiceLine, i) {
            supplierInvoiceLine.lineNo = i + 1;
        });
        this.selectedSupplierInvoice.lines = this.selectedSupplierInvoice.lines.slice();
        if (!this.isXE() && !this.isZE()) {
            if (this.selectedSupplierInvoice.lines.length === 0) {
                this.selectedSupplierInvoice.lines.push(this.recreateSupplierInvoiceLine());
                this.selectedSupplierInvoice.lines = this.selectedSupplierInvoice.lines.slice();
            }
            if (index === 0) {
                this.selectedInvoiceLine = this.selectedSupplierInvoice.lines[0];
            }
            else {
                this.selectedInvoiceLine = this.selectedSupplierInvoice.lines[index - 1];
            }
        }
    };
    InvoiceLinesComponent.prototype.recreateSupplierInvoiceLine = function () {
        var createSupplierInvoiceLine = new SupplierInvoiceLine();
        return this.shareDataService.addSupplierInvoiceLine(this.selectedSupplierInvoice, createSupplierInvoiceLine);
    };
    InvoiceLinesComponent.prototype.showErrors = function (label, messages) {
        if (messages) {
            this.notificationService.errorNotify(label, messages);
        }
        else {
            this.notificationService.errorNotify(label);
        }
        return true;
    };
    InvoiceLinesComponent.prototype.onClose = function () {
        this.showDialog = false;
    };
    InvoiceLinesComponent.prototype.searchBoes = function () {
        var _this = this;
        this.lines = [];
        var code = this.file.clearingInstructions[0].previousCustomsProcedureCode;
        if (this.file.clearingInstructions[0].customsPurpose.customsPurposeCode.trim() === 'ZE') {
            code = 42;
        }
        this.billOfEntryService.findByCustomsProcedureCodeAndMrn(code ? code : 42, null).subscribe(function (boes) {
            boes.forEach(function (boe) {
                var lines = boe.lines.map(function (line) { return Object.assign({}, line); });
                lines.forEach(function (line) {
                    var previousBoEDetails = new PreviousBoeDetails();
                    previousBoEDetails.cpc = boe.customsPurposeCode;
                    previousBoEDetails.customsBOENo = boe.mrn;
                    previousBoEDetails.date = boe.releaseDate;
                    previousBoEDetails.lineNo = line.lineNo;
                    previousBoEDetails.customsValue = line.customsValue;
                    previousBoEDetails.customsDuty = line.customsDuty;
                    previousBoEDetails.customsVAT = line.customsVAT;
                    previousBoEDetails.localCustomsValue = line.localCustomsValue;
                    previousBoEDetails.actualValue = line.actualValue;
                    previousBoEDetails.noOfPacks = line.noOfPacks;
                    if (line.warehouseCaptureDetails) {
                        line.noOfPacks = line.warehouseCaptureDetails.noOfPacksAtWarehouse;
                        previousBoEDetails.remainingPacks = line.warehouseCaptureDetails.noOfPacksAtWarehouse;
                    }
                    else {
                        previousBoEDetails.remainingPacks = line.noOfPacks;
                    }
                    previousBoEDetails.lineQuantity = line.lineQuantity;
                    previousBoEDetails.lineWeight = line.lineWeight;
                    line.previousBoeDetails = Object.assign({}, previousBoEDetails);
                    line.type = 'ExportBillOfEntryLine';
                    _this.recalculateValues(line);
                });
                (_a = _this.lines).push.apply(_a, lines);
                var _a;
            });
            console.log(boes);
            _this.lines = _this.lines.slice();
            _this.file.clearingInstructions[0].customsPurpose.customsPurposeCode === 'ZE ';
        });
    };
    InvoiceLinesComponent.prototype.recalculateValues = function (rowData) {
        var ratio = 1;
        if (rowData.noOfPacks) {
            ratio = rowData.noOfPacks / rowData.previousBoeDetails.noOfPacks;
        }
        rowData.customsValue = rowData.previousBoeDetails.customsValue * ratio;
        rowData.customsVAT = rowData.previousBoeDetails.customsVAT * ratio;
        rowData.customsDuty = rowData.previousBoeDetails.customsDuty * ratio;
        rowData.localCustomsValue = rowData.previousBoeDetails.localCustomsValue * ratio;
        rowData.actualValue = rowData.previousBoeDetails.actualValue * ratio;
        rowData.lineQuantity = rowData.previousBoeDetails.lineQuantity * ratio;
        rowData.lineWeight = rowData.previousBoeDetails.lineWeight * ratio;
    };
    InvoiceLinesComponent.prototype.deselectBoes = function () {
        this.selectedLines = [];
    };
    InvoiceLinesComponent.prototype.createInvoiceLines = function () {
        var _this = this;
        if (!this.selectedSupplierInvoice.lines || (this.selectedSupplierInvoice.lines && this.selectedSupplierInvoice.lines.length === 0)) {
            this.selectedSupplierInvoice.lines = [];
        }
        this.selectedLines.forEach(function (line, index) {
            var supplierInvoiceLine = _this.shareDataService
                .addSupplierInvoiceLine(_this.selectedSupplierInvoice, new SupplierInvoiceLine());
            supplierInvoiceLine.lineNo = _this.selectedSupplierInvoice.lines.length + 1;
            // @ts-ignore
            supplierInvoiceLine.tariffCode = Object.assign({}, line.tariffCode);
            supplierInvoiceLine.partNumber = line.partNumber;
            supplierInvoiceLine.description = line.description;
            supplierInvoiceLine.fullDescription = line.fullDescription;
            supplierInvoiceLine.invoiceGrossValue = line.actualValue;
            supplierInvoiceLine.customsValue = line.customsValue;
            supplierInvoiceLine.lineQuantity = line.lineQuantity;
            supplierInvoiceLine.lineWeight = line.lineWeight;
            supplierInvoiceLine.invoiceValue = line.actualValue;
            supplierInvoiceLine.previousBoeDetails = line.previousBoeDetails;
            supplierInvoiceLine.noOfPacks = line.noOfPacks;
            supplierInvoiceLine.packType = line.packType;
            supplierInvoiceLine.itemsPerPack = line.itemsPerPack;
            supplierInvoiceLine.tradeAgreement = line.tradeAgreement;
            supplierInvoiceLine.countryOfOrigin = line.countryOfOrigin;
            _this.setCustomsPurposeAndClearingInstruction(supplierInvoiceLine);
            _this.selectedSupplierInvoice.lines.push(supplierInvoiceLine);
        });
        this.selectedSupplierInvoice.totalGrossIncomeValue = this.selectedSupplierInvoice.lines
            .reduce(function (prev, curr) { return prev + curr.invoiceValue; }, 0);
        this.selectedSupplierInvoice.quantity = this.selectedSupplierInvoice.lines
            .reduce(function (prev, curr) { return prev + curr.lineWeight; }, 0);
        this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(this.selectedSupplierInvoice.lines[0]);
        this.showDialog = false;
    };
    return InvoiceLinesComponent;
}());
export { InvoiceLinesComponent };
