import {ClearingFile} from '../../../models/clearingFile';
import {
  ClearingInstruction,
  CustomsPurpose,
  ExportTransportDocument,
  PreviousBoeDetails,
  SupplierInvoice,
  SupplierInvoiceLine,
  TransportDocument
} from '../../../models/file';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ShareDataService} from '../../../services/share-data.service';
import {SupplierInvoiceLineBehaviourSubject} from '../../../../../subjects/supplier-invoice-line-behaviour-subject.service';
import {SupplierInvoiceBehaviourSubject} from '../../../../../subjects/supplierInvoice-behaviour-subject';
import {Subscription} from 'rxjs';
import {ClearingFileBehaviourSubject} from '../../../../../subjects/clearingfile-behaviour-subject';
import {FileType} from '../../../models/enumerations';
import {ExportFileBehaviourSubject} from '../../../../../subjects/exportfile-behaviour-subject';
import {ExportFile} from '../../../models/exportFile';
import {UniqueConsignmentBehaviourSubject} from '../../../../../subjects/unique-consignment-reference-subject';
import {TransportDocumentBehaviourSubject} from '../../../../../subjects/transport-document-behaviour-subject';
import {ValidationService} from '../../../services/validation.service';
import {NotificationService} from '../../../services/notification.service';
import {SaveClearingFile} from '../../../saveClearingFile';
import {ExportFileService} from '../../../services/export-file.service';
import {ExBondLine} from '../../../models/exBondFile';
import {ExportBillOfEntryLine} from '../../../models/billOfEntries';
import {BillOfEntryService} from '../../../services/bill-of-entry.service';

@Component({
  selector: 'digi-invoice-lines',
  templateUrl: './invoice-lines.component.html',
  styleUrls: ['./invoice-lines.component.css'],
})
export class InvoiceLinesComponent implements OnInit, OnDestroy {
  file: ClearingFile | ExportFile;
  selectedInvoiceLine: SupplierInvoiceLine;
  selectedSupplierInvoice: SupplierInvoice;
  subscription: Subscription;
  siSubscription: Subscription;
  lineSubscription: Subscription;
  filteredCustomPurposes: any[];
  selectedCustomsPurpose: CustomsPurpose;
  clearingInstruction: ClearingInstruction;
  display = false;
  @Input() fileType: FileType;
  transportDocumentSubscription: Subscription;
  transportDocument: TransportDocument | ExportTransportDocument;
  disableButtons: boolean;
  cols: any[];
  showDialog: boolean;
  boeLinesCols: any[];
  lines: ExportBillOfEntryLine[];
  selectedLines: ExportBillOfEntryLine[];


  constructor(private shareDataService: ShareDataService,
              private supplierInvoiceLineBehaviourSubjectComponent: SupplierInvoiceLineBehaviourSubject,
              private clearingFileBehaviourSubject: ClearingFileBehaviourSubject,
              private supplierInvoiceBehaviourSubject: SupplierInvoiceBehaviourSubject,
              private exportFileBehaviourSubject: ExportFileBehaviourSubject,
              private uniqueConsignmentBehaviourSubject: UniqueConsignmentBehaviourSubject,
              private transportDocumentBehaviorSubject: TransportDocumentBehaviourSubject,
              private validationService: ValidationService,
              private notificationService: NotificationService,
              private saveClearingFile: SaveClearingFile,
              private exportFileService: ExportFileService,
              private billOfEntryService: BillOfEntryService
  ) {
  }

  ngOnInit() {
    this.cols = [
      {field: 'lineNo', header: 'Line', width: '11%'},
      {field: 'code', header: 'Tarrif'},
      {field: 'invoiceGrossValue', header: 'Value'},
    ];
    this.boeLinesCols = [
      {
        width: '5%'
      },
      {
        header: 'Line No',
        width: '5%'
      },
      {
        header: 'MRN',
        width: '15%'
      },
      {
        header: 'CPC',
        width: '5%'
      },
      {
        header: 'Customs Date',
        width: '10%'
      },
      {
        header: 'Part Number',
        width: '15%'
      },
      {
        header: 'Available Packs',
        width: '10%'
      },
      {
        header: 'Selected Packs',
        width: '10%'
      },
      {
        header: 'Pack Type',
        width: '10%'
      },
      {
        header: 'Value',
        width: '10%'
      },
      {
        header: 'Qty',
        width: '5%'
      }
    ];
    if (this.fileType === 'imports') {
      this.subscription = this.clearingFileBehaviourSubject.getClearingFile()
        .subscribe(cf => {
          this.file = cf;
          if (this.siSubscription) {
            this.siSubscription.unsubscribe();
          }
          this.siSubscription = this.supplierInvoiceBehaviourSubject
            .getSupplierInvoice()
            .subscribe(si => {
              this.selectedSupplierInvoice = si;
              if (!this.selectedSupplierInvoice.lines) {
                this.selectedSupplierInvoice.lines = [];
              }
              if (!this.selectedSupplierInvoice.lines.length) {
                const createSupplierInvoiceLine: SupplierInvoiceLine = new SupplierInvoiceLine();
                this.selectedInvoiceLine =
                  this.shareDataService.addSupplierInvoiceLine(this.selectedSupplierInvoice, createSupplierInvoiceLine);
                this.selectedInvoiceLine.ucrNumber = this.selectedSupplierInvoice.ucrNumber;
                if (this.selectedSupplierInvoice.ucr) {
                  this.selectedInvoiceLine.ucrCreditTerms = this.selectedSupplierInvoice.ucr.paymentTerm.code;
                  this.selectedInvoiceLine.ucrTransactionValue = this.selectedSupplierInvoice.ucr.transactionValue;
                  this.selectedInvoiceLine.ucrTransactionValueCurrency = this.selectedSupplierInvoice.ucr.transactionCurrency.code;
                }
                this.selectedSupplierInvoice.lines.push(this.selectedInvoiceLine);
                this.selectedSupplierInvoice.lines = [...this.selectedSupplierInvoice.lines];
                this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(this.selectedInvoiceLine);
              } else {
                this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(this.selectedSupplierInvoice.lines[0]);
              }
            });

          if (this.lineSubscription) {
            this.lineSubscription.unsubscribe();
          }

          this.lineSubscription = this.supplierInvoiceLineBehaviourSubjectComponent
            .getSupplierInvoiceLine()
            .subscribe(line => {
              if (line) {
                if (!line.costingUom) {
                  line.costingUom = Object.assign({}, this.file.importer.costingUom);
                }
                this.selectedInvoiceLine = this.selectedSupplierInvoice.lines.find(l => l.lineNo === line.lineNo);
                if (this.selectedInvoiceLine.instructionIndex) {
                  this.clearingInstruction = this.file.clearingInstructions[this.selectedInvoiceLine.instructionIndex];
                  this.populateClearingInstructionSelection();
                } else {
                  this.setCustomsPurposeAndClearingInstruction(this.selectedInvoiceLine);
                }
              }
            });
        });
    } else {
      this.subscription = this.exportFileBehaviourSubject.getExportFile()
        .subscribe(ef => {
          this.file = ef;
          if (this.siSubscription) {
            this.siSubscription.unsubscribe();
          }
          this.siSubscription = this.supplierInvoiceBehaviourSubject
            .getSupplierInvoice()
            .subscribe(si => {
              this.selectedSupplierInvoice = si;
              if (!this.selectedSupplierInvoice.lines) {
                this.selectedSupplierInvoice.lines = [];
              }
              if (!this.selectedSupplierInvoice.lines.length) {
                if (!this.isXE() && !this.isZE() || this.selectedSupplierInvoice.manualExWarehouseLineCapture) {
                  const createSupplierInvoiceLine: SupplierInvoiceLine = new SupplierInvoiceLine();
                  const supplierInvoiceLine: SupplierInvoiceLine =
                    this.shareDataService.addSupplierInvoiceLine(this.selectedSupplierInvoice, createSupplierInvoiceLine);
                  this.selectedSupplierInvoice.lines.push(supplierInvoiceLine);
                  this.selectedSupplierInvoice.lines = [...this.selectedSupplierInvoice.lines];
                  this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(supplierInvoiceLine);
                }
              } else {
                this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(this.selectedSupplierInvoice.lines[0]);
              }
              this.uniqueConsignmentBehaviourSubject.addUniqueConsignmentReference(this.selectedSupplierInvoice.ucr);
            });

          if (this.lineSubscription) {
            this.lineSubscription.unsubscribe();
          }

          this.lineSubscription = this.supplierInvoiceLineBehaviourSubjectComponent
            .getSupplierInvoiceLine()
            .subscribe(line => {
              if (line) {
                this.selectedInvoiceLine = this.selectedSupplierInvoice.lines.find(l => l.lineNo === line.lineNo);
                this.selectedInvoiceLine.ucrNumber = this.selectedSupplierInvoice.ucrNumber;
                if (this.selectedSupplierInvoice.ucr) {
                  this.selectedInvoiceLine.ucrCreditTerms = this.selectedSupplierInvoice.ucr.paymentTerm.code;
                  this.selectedInvoiceLine.ucrTransactionValue = this.selectedSupplierInvoice.ucr.transactionValue;
                  this.selectedInvoiceLine.ucrTransactionValueCurrency = this.selectedSupplierInvoice.ucr.transactionCurrency.code;
                }
                if (this.selectedInvoiceLine.instructionIndex !== undefined && this.selectedInvoiceLine.instructionIndex !== null) {
                  this.clearingInstruction = this.file.clearingInstructions[this.selectedInvoiceLine.instructionIndex];
                  this.populateClearingInstructionSelection();
                } else {
                  this.setCustomsPurposeAndClearingInstruction(this.selectedInvoiceLine);
                }
              }
            });
        });
    }

    this.transportDocumentSubscription = this.transportDocumentBehaviorSubject.getTransportDocuments().subscribe(td => {
      this.transportDocument = td;
    });
  }

  hasSupplierInvoiceLine() {
    return this.selectedSupplierInvoice && this.selectedSupplierInvoice.lines && this.selectedSupplierInvoice.lines.length > 0;
  }

  handleManualExWarehouseCaptureChange(event) {
    if (!this.selectedSupplierInvoice.manualExWarehouseLineCapture) {
      this.selectedSupplierInvoice.lines = [];
      const createSupplierInvoiceLine: SupplierInvoiceLine = new SupplierInvoiceLine();
      this.selectedInvoiceLine =
        this.shareDataService.addSupplierInvoiceLine(this.selectedSupplierInvoice, createSupplierInvoiceLine);
      this.selectedInvoiceLine.ucrNumber = this.selectedSupplierInvoice.ucrNumber;
      if (this.selectedSupplierInvoice.ucr) {
        this.selectedInvoiceLine.ucrCreditTerms = this.selectedSupplierInvoice.ucr.paymentTerm.code;
        this.selectedInvoiceLine.ucrTransactionValue = this.selectedSupplierInvoice.ucr.transactionValue;
        this.selectedInvoiceLine.ucrTransactionValueCurrency = this.selectedSupplierInvoice.ucr.transactionCurrency.code;
      }
    } else if (!this.selectedInvoiceLine) {
      const createSupplierInvoiceLine: SupplierInvoiceLine = new SupplierInvoiceLine();
      const supplierInvoiceLine: SupplierInvoiceLine =
        this.shareDataService.addSupplierInvoiceLine(this.selectedSupplierInvoice, createSupplierInvoiceLine);
      this.selectedSupplierInvoice.lines.push(supplierInvoiceLine);
      this.selectedSupplierInvoice.lines = [...this.selectedSupplierInvoice.lines];
      this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(supplierInvoiceLine);
    }
  }

  addInvoiceLines() {
    if (!this.isXE() && !this.isZE() || this.selectedSupplierInvoice.manualExWarehouseLineCapture) {
      this.disableButtons = true;
      const failed = this.validationService.validateFileBasedOnStep('supplierInvoice', this.file, this.fileType,
        (header: string, messages: string[]) => {
          this.showErrors(header, messages);
          this.disableButtons = false;
        });

      if (!failed) {
        if (this.fileType === 'imports') {
          this.saveClearingFile.saveClearingFile(this.file, this.transportDocument, this.selectedSupplierInvoice, this.selectedInvoiceLine)
            .subscribe(
              clearingFile => {
                this.file = clearingFile;
                this.notificationService.successNotify('File Saved Successfully');
                this.addLine();
                this.disableButtons = false;
              },
              _ => this.disableButtons = false
            );
        } else {
          this.exportFileService.saveExportsFile(this.file, this.transportDocument, this.selectedSupplierInvoice, this.selectedInvoiceLine)
            .subscribe(
              exportFile => {
                this.file = exportFile;
                this.notificationService.successNotify('File Saved Successfully');
                this.addLine();
                this.disableButtons = false;
              },
              _ => this.disableButtons = false
            );
        }
      }
    } else {
      this.showDialog = true;
      this.searchBoes();
    }
  }

  isXE() {
    return this.file && this.file.clearingInstructions[0].customsPurpose.customsPurposeCode.trim() === 'XE';
  }

  isZE() {
    return this.file && this.file.clearingInstructions[0].customsPurpose.customsPurposeCode.trim() === 'ZE';
  }

  private addLine() {
    const createSupplierInvoiceLine: SupplierInvoiceLine = new SupplierInvoiceLine();
    this.setCustomsPurposeAndClearingInstruction(createSupplierInvoiceLine);
    const invoiceLine: SupplierInvoiceLine = this.shareDataService
      .addSupplierInvoiceLine(this.selectedSupplierInvoice, createSupplierInvoiceLine);
    this.selectedSupplierInvoice.lines.push(invoiceLine);
    this.selectedSupplierInvoice.lines = [...this.selectedSupplierInvoice.lines];
    this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(invoiceLine);
  }

  searchCustomsPurpose(event) {
    const query = event.query;
    this.filteredCustomPurposes = this.file.clearingInstructions
      .filter(instruction => instruction.customsPurpose.customsPurposeName.toLowerCase().indexOf(query.toLowerCase()) === 0);
  }

  selectCustomsPurpose(event) {
    this.selectedCustomsPurpose = Object.assign({}, event.customsPurpose);
    const index = this.file.clearingInstructions.indexOf(event);
    this.clearingInstruction = event;
    this.selectedInvoiceLine.instructionIndex = index;
    this.selectedInvoiceLine.instructionUUID = this.clearingInstruction.uuid;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.siSubscription) {
      this.siSubscription.unsubscribe();
    }
    if (this.lineSubscription) {
      this.lineSubscription.unsubscribe();
    }
    if (this.transportDocumentSubscription) {
      this.transportDocumentSubscription.unsubscribe();
    }
  }

  private setCustomsPurposeAndClearingInstruction(line: SupplierInvoiceLine) {
    line.instructionIndex = 0;
    this.clearingInstruction = this.file.clearingInstructions[0];
    line.instructionUUID = this.file.clearingInstructions[0].uuid;
    if (line.customsVAT === undefined) {
      line.customsVAT = this.clearingInstruction.customVatRequired;
    }
    this.populateClearingInstructionSelection();
  }

  private populateClearingInstructionSelection() {
    this.selectedCustomsPurpose = this.clearingInstruction.customsPurpose;
  }

  openClearingInstructionModel() {
    this.display = true;
  }

  onDialogClose(event) {
    this.display = event;
  }

  selectInvoiceLine(value) {
    this.selectedInvoiceLine = value;
    this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(this.selectedInvoiceLine);
  }

  deleteLine(index: number) {
    this.selectedSupplierInvoice.lines.splice(index, 1);
    this.selectedSupplierInvoice.lines.forEach((supplierInvoiceLine, i) => {
      supplierInvoiceLine.lineNo = i + 1;
    });
    this.selectedSupplierInvoice.lines = [...this.selectedSupplierInvoice.lines];
    if (!this.isXE() && !this.isZE()) {
      if (this.selectedSupplierInvoice.lines.length === 0) {
        this.selectedSupplierInvoice.lines.push(this.recreateSupplierInvoiceLine());
        this.selectedSupplierInvoice.lines = [...this.selectedSupplierInvoice.lines];
      }
      if (index === 0) {
        this.selectedInvoiceLine = this.selectedSupplierInvoice.lines[0];
      } else {
        this.selectedInvoiceLine = this.selectedSupplierInvoice.lines[index - 1];
      }
    }
  }

  private recreateSupplierInvoiceLine() {
    const createSupplierInvoiceLine: SupplierInvoiceLine = new SupplierInvoiceLine();
    return this.shareDataService.addSupplierInvoiceLine(this.selectedSupplierInvoice, createSupplierInvoiceLine);
  }

  showErrors(label: string, messages?: string[]) {
    if (messages) {
      this.notificationService.errorNotify(label, messages);
    } else {
      this.notificationService.errorNotify(label);
    }
    return true;
  }

  onClose() {
    this.showDialog = false;
  }

  searchBoes() {
    this.lines = [];
    let code = this.file.clearingInstructions[0].previousCustomsProcedureCode;
    if (this.file.clearingInstructions[0].customsPurpose.customsPurposeCode.trim() === 'ZE') {
      code = 42;
    }
    this.billOfEntryService.findByCustomsProcedureCodeAndMrn(code ? code : 42, null).subscribe(
      boes => {
        boes.forEach(boe => {
            const lines = boe.lines.map(line => Object.assign({}, line));
            lines.forEach(line => {
              const previousBoEDetails = new PreviousBoeDetails();
              previousBoEDetails.cpc = boe.customsPurposeCode;
              previousBoEDetails.customsBOENo = boe.mrn;
              previousBoEDetails.date = boe.releaseDate;
              previousBoEDetails.lineNo = line.lineNo;
              previousBoEDetails.customsValue = line.customsValue;
              previousBoEDetails.customsDuty = line.customsDuty;
              previousBoEDetails.customsVAT = line.customsVAT;
              previousBoEDetails.localCustomsValue = line.localCustomsValue;
              previousBoEDetails.actualValue = line.actualValue;
              previousBoEDetails.noOfPacks = line.noOfPacks;
              if (line.warehouseCaptureDetails) {
                line.noOfPacks = line.warehouseCaptureDetails.noOfPacksAtWarehouse;
                previousBoEDetails.remainingPacks = line.warehouseCaptureDetails.noOfPacksAtWarehouse;
              } else {
                previousBoEDetails.remainingPacks = line.noOfPacks;
              }
              previousBoEDetails.lineQuantity = line.lineQuantity;
              previousBoEDetails.lineWeight = line.lineWeight;
              line.previousBoeDetails = Object.assign({}, previousBoEDetails);
              line.type = 'ExportBillOfEntryLine';
              this.recalculateValues(line);
            });
            this.lines.push(...lines);
          }
        );
        console.log(boes);
        this.lines = [...this.lines];
        this.file.clearingInstructions[0].customsPurpose.customsPurposeCode === 'ZE '
      }
    );
  }
  recalculateValues(rowData) {
    let ratio = 1;
    if (rowData.noOfPacks) {
      ratio = rowData.noOfPacks / rowData.previousBoeDetails.noOfPacks;
    }
    rowData.customsValue = rowData.previousBoeDetails.customsValue * ratio;
    rowData.customsVAT = rowData.previousBoeDetails.customsVAT * ratio;
    rowData.customsDuty = rowData.previousBoeDetails.customsDuty * ratio;
    rowData.localCustomsValue = rowData.previousBoeDetails.localCustomsValue * ratio;
    rowData.actualValue = rowData.previousBoeDetails.actualValue * ratio;
    rowData.lineQuantity = rowData.previousBoeDetails.lineQuantity * ratio;
    rowData.lineWeight = rowData.previousBoeDetails.lineWeight * ratio;
  }

  deselectBoes () {
    this.selectedLines = [];
  }

  createInvoiceLines() {
    if (!this.selectedSupplierInvoice.lines || (this.selectedSupplierInvoice.lines && this.selectedSupplierInvoice.lines.length === 0)) {
      this.selectedSupplierInvoice.lines = [];
    }
    this.selectedLines.forEach((line, index) => {
      const supplierInvoiceLine = this.shareDataService
        .addSupplierInvoiceLine(this.selectedSupplierInvoice, new SupplierInvoiceLine());
      supplierInvoiceLine.lineNo = this.selectedSupplierInvoice.lines.length + 1;
      // @ts-ignore
      supplierInvoiceLine.tariffCode = Object.assign({}, line.tariffCode);
      supplierInvoiceLine.partNumber = line.partNumber;
      supplierInvoiceLine.description = line.description;
      supplierInvoiceLine.fullDescription = line.fullDescription;
      supplierInvoiceLine.invoiceGrossValue = line.actualValue;
      supplierInvoiceLine.customsValue = line.customsValue;
      supplierInvoiceLine.lineQuantity = line.lineQuantity;
      supplierInvoiceLine.lineWeight = line.lineWeight;
      supplierInvoiceLine.invoiceValue = line.actualValue;
      supplierInvoiceLine.previousBoeDetails = line.previousBoeDetails;
      supplierInvoiceLine.noOfPacks = line.noOfPacks;
      supplierInvoiceLine.packType = line.packType;
      supplierInvoiceLine.itemsPerPack = line.itemsPerPack;
      supplierInvoiceLine.tradeAgreement = line.tradeAgreement;
      supplierInvoiceLine.countryOfOrigin = line.countryOfOrigin;

      this.setCustomsPurposeAndClearingInstruction(supplierInvoiceLine);

      this.selectedSupplierInvoice.lines.push(supplierInvoiceLine);
    });
    this.selectedSupplierInvoice.totalGrossIncomeValue = this.selectedSupplierInvoice.lines
      .reduce((prev, curr) => prev + curr.invoiceValue, 0);
    this.selectedSupplierInvoice.quantity = this.selectedSupplierInvoice.lines
      .reduce((prev, curr) => prev + curr.lineWeight, 0);
    this.supplierInvoiceLineBehaviourSubjectComponent.addSupplierInvoiceLine(this.selectedSupplierInvoice.lines[0]);
    this.showDialog = false;
  }
}
