import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {FileUploadModule} from 'primeng/fileupload';
import {InputMaskModule} from 'primeng/inputmask';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MessageModule} from 'primeng/message';
import {MessagesModule} from 'primeng/messages';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PanelModule} from 'primeng/panel';
import {
    CardModule, ChipsModule, ConfirmDialogModule,
    MenubarModule,
    MenuModule,
    PanelMenuModule, PickListModule,
    ScrollPanelModule,
    SelectButtonModule,
    SidebarModule,
    TabMenuModule
} from 'primeng/primeng';
import {RadioButtonModule} from 'primeng/radiobutton';
import {StepsModule} from 'primeng/steps';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {ToastModule} from 'primeng/toast';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import {InvoiceLinesTransportDocsComponent} from '../digi-air/components/invoice-lines-transport-docs/invoice-lines-transport-docs.component';
import {AddressComponent} from './components/address/address.component';
import {BeforeAndAfterPaymentDetailsComponent} from './components/before-and-after-payment-details/before-and-after-payment-details.component';
import {BillOfEntryTableComponent} from './components/bill-of-entry-table/bill-of-entry-table.component';
import {BillOfEntryComponent} from './components/bill-of-entry/bill-of-entry.component';
import {BoeBankDetailsComponent} from './components/boe-bank-details/boe-bank-details.component';
import {BoeFileSectionComponent} from './components/boe-file-section/boe-file-section.component';
import {GenerateComponent} from './components/boe-generator/generate/generate.component';
import {BoeLineAdditionalInfoComponent} from './components/boe-line-additional-info/boe-line-additional-info.component';
import {BoeLineDetailsComponent} from './components/boe-line-details/boe-line-details.component';
import {BoeLineOriginalBoeComponent} from './components/boe-line-original-boe/boe-line-original-boe.component';
import {BoeLineUnitsQuantityComponent} from './components/boe-line-units-quantity/boe-line-units-quantity.component';
import {BoeLinesTabTariffDescriptionSectionComponent} from './components/boe-lines-tab-tariff-description-section/boe-lines-tab-tariff-description-section.component';
import {BoeLinesTableSectionComponent} from './components/boe-lines-table-section/boe-lines-table-section.component';
import {BoeLinesTopSectionComponent} from './components/boe-lines-top-section/boe-lines-top-section.component';
import {BoeLinesComponent} from './components/boe-lines/boe-lines.component';
import {BoeMarksNumbersComponent} from './components/boe-marks-numbers/boe-marks-numbers.component';
import {BoePackageSectionComponent} from './components/boe-package-section/boe-package-section.component';
import {BoePaymentDetailsComponent} from './components/boe-payment-details/boe-payment-details.component';
import {BoePermitsSectionComponent} from './components/boe-permits-section/boe-permits-section.component';
import {ClearingAgentComponent} from './components/business-entity/clearing-agent/clearing-agent.component';
import {ImporterDebtorsComponent} from './components/business-entity/importer-debtors/importer-debtors.component';
import {ImporterComponent} from './components/business-entity/importer/importer.component';
import {SupplierComponent} from './components/business-entity/supplier/supplier.component';
import {ClearingFileSearchComponent} from './components/clearing-file-search/clearing-file-search.component';
import {ClearingFileSectionComponent} from './components/clearing-file-section/clearing-file-section.component';
import {ClearingInstructionModalComponent} from './components/clearing-Instruction-modal/clearing-instruction-modal.component';
import {ConfirmationSectionComponent} from './components/confirmation-section/confirmation-section.component';
import {ConsolidationDetailsSectionComponent} from './components/consolidation-details-section/consolidation-details-section.component';
import {ConsolidationDetailsTableComponent} from './components/consolidation-details-table/consolidation-details-table.component';
import {CorrectionComponent} from './components/correction/correction.component';
import {CountryOfOriginComponent} from './components/country-of-origin/country-of-origin.component';
import {CurrencyNestedComponent} from './components/currency-nested/currency.nested.component';
import {CurrencyComponent} from './components/currency/currency.component';
import {DependentComponent} from './components/dependent/dependent.component';
import {DigiBoeLineScheduleAmountComponent} from './components/digi-boe-line-schedule-amount/digi-boe-line-schedule-amount.component';
import {ExchangeRatesComponent} from './components/exchange-rates/exchange-rates.component';
import {HawbBoeDetailsSectionComponent} from './components/hawb-boe-details-section/hawb-boe-details-section.component';
import {HawbFreightSectionComponent} from './components/hawb-freight-section/hawb-freight-section.component';
import {HawbPortToComponent} from './components/hawb-port-to/hawb-port-to.component';
import {HawbSummaryTableComponent} from './components/hawb-summary-table/hawb-summary-table.component';
import {HawbWeightMeasuresSectionComponent} from './components/hawb-weight-measures-section/hawb-weight-measures-section.component';
import {InvoiceDefaultValuesComponent} from './components/invoice-default-values/invoice-default-values.component';
import {InvoiceLinesDefaultsSectionComponent} from './components/invoice-lines-defaults-section/invoice-lines-defaults-section.component';
import {InvoiceLinesDutyPaidModalComponent} from './components/invoice-lines-duty-paid-modal/invoice-lines-duty-paid-modal.component';
import {InvoiceLinesSectionComponent} from './components/invoice-lines-section/invoice-lines-section.component';
import {InvoiceLinesTableComponent} from './components/invoice-lines-table/invoice-lines-table.component';
import {InvoiceSupplierImporterDetailsComponent} from './components/invoice-supplier-importer-details/invoice-supplier-importer-details.component';
import {MarksNumbersComponent} from './components/marks-numbers/marks-numbers.component';
import {NotificationComponent} from './components/notification/notification.component';
import {PermitsTableComponent} from './components/permits-table/permits-table.component';
import {PortDetailsComponent} from './components/port-details/port-details.component';
import {PurchaseTermsComponent} from './components/purchase-terms/purchase-terms.component';
import {RateBasedChargeComponent} from './components/rate-based-charge/rate-based-charge.component';
import {RegistrationReferencesSectionComponent} from './components/registration-references-section/registration-references-section.component';
import {SelectedTariffSectionComponent} from './components/selected-tariff-section/selected-tariff-section.component';
import {SequenceModalComponent} from './components/sequence-modal/sequence-modal.component';
import {CollectOrPrepaidSearchComponent} from './components/sub-components/collect-or-prepaid-search/collect-or-prepaid-search.component';
import {CustomsOfficeComponent} from './components/sub-components/customs-office/customs-office.component';
import {DegroupSearchComponent} from './components/sub-components/degroup-search/degroup-search.component';
import {FileIncoTermComponent} from './components/sub-components/file-inco-term/file-inco-term.component';
import {RebateUserComponent} from './components/sub-components/rebate-user/rebate-user.component';
import {SundrySearchComponent} from './components/sub-components/sundry-search/sundry-search.component';
import {SundryChargesComponent} from './components/sundry-charges/sundry-charges.component';
import {SupplierInvoiceTableComponent} from './components/supplier-invoice-table/supplier-invoice-table.component';
import {InvoiceHeadersComponent} from './components/supplier-invoice/invoice-headers/invoice-headers.component';
import {InvoiceLinesComponent} from './components/supplier-invoice/invoice-lines/invoice-lines.component';
import {TariffDependentComponent} from './components/tariff-dependent/tariff-dependent.component';
import {TariffFormulaeTableComponent} from './components/tariff-formulae-table/tariff-formulae-table.component';
import {TariffFormulaeComponent} from './components/tariff-formulae/tariff-formulae.component';
import {TariffMainMaintenanceComponent} from './components/tariff-main-maintenance/tariff-main-maintenance.component';
import {TariffMaintenanceDescriptionComponent} from './components/tariff-maintenance-description/tariff-maintenance-description.component';
import {TariffMaintenanceTableDescriptionComponent} from './components/tariff-maintenance-table-description/tariff-maintenance-table-description.component';
import {TariffMaintenanceComponent} from './components/tariff-maintenance/tariff-maintenance.component';
import {TotalWeightQuantityComponent} from './components/total-weight-quantity/total-weight-quantity.component';
import {TradeAgreementComponent} from './components/trade/trade-agreement/trade-agreement.component';
import {ValueBasedChargeComponent} from './components/value-based-charge/value-based-charge.component';
import {COMMON_ROUTES} from './digi-common.routes';
import {BusinessEntityPipe} from './pipes/business-entity.pipe';
import {CountryPipe} from './pipes/country.pipe';
import {CurrencyPipe} from './pipes/currency.pipe';
import {PermitNumbersPipe} from './pipes/permit-numbers.pipe';
import {PurchaseTermsPipe} from './pipes/purchase-terms.pipe';
import {ReplaceUnderScoresPipe} from './pipes/replace-under-scores.pipe';
import {SearchFilterPipePipe} from './pipes/search-filter-pipe.pipe';
import {TariffCodePipe} from './pipes/tariff-code.pipe';
import {TradeAgreementsPipe} from './pipes/trade-agreements.pipe';
import {PrimaryNavigationComponent} from './primary-navigation/primary-navigation.component';
import {SarsDashboardComponent} from './sars-dashboard/sars-dashboard.component';
import {TariffMaintenanceNavigationComponent} from './tariff-maintenance-navigation/tariff-maintenance-navigation.component';
import {TravelTypesComponent} from './travel-types/travel-types.component';
import {FinSupplrinvHeadersComponent} from './components/financials/fin-supplrinv-headers/fin-supplrinv-headers.component';
import {FinInvdetailsTotalComponent} from './components/financials/fin-invdetails-total/fin-invdetails-total.component';
import {FinInvdetailsValuesComponent} from './components/financials/fin-invdetails-values/fin-invdetails-values.component';
import {LocalForwarderComponent} from './components/local-forwarder/local-forwarder.component';
import {DepotComponent} from './components/depot/depot.component';
import {GuaranteeTypeComponent} from './components/guarantee-type/guarantee-type.component';
import {WarehouseDetailsComponent} from './components/business-entity/warehouse-details/warehouse-details.component';
import {AdditionalScheduleTariffComponent} from './components/additional-schedule-tariff/additional-schedule-tariff.component';
import {RemoverComponent} from './components/business-entity/remover/remover.component';
import {DocumentsComponent} from './components/documents/documents.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {LoadingComponent} from './components/loader/loading.component';
import {GuarantorComponent} from './components/business-entity/guarantor/guarantor.component';
import {ConsigneeComponent} from './components/business-entity/consignee/consignee.component';
import {CustomsOfficeConsigneeComponent} from './components/business-entity/consignee-details/customs-office-consignee/customs-office-consignee.component';
import {ImporterConsigneeComponent} from './components/business-entity/consignee-details/importer-consignee/importer-consignee.component';
import {LocalAgentConsigneeComponent} from './components/business-entity/consignee-details/local-agent-consignee/local-agent-consignee.component';
import {WarehouseConsigneeComponent} from './components/business-entity/consignee-details/warehouse-consignee/warehouse-consignee.component';
import {OverseasAgentConsigneeComponent} from './components/business-entity/consignee-details/overseas-agent-consignee/overseas-agent-consignee.component';
import {ClearingInstructionImporterConsigneeComponent} from './components/business-entity/consignee-details/clearing-instruction-importer-consignee/clearing-instruction-importer-consignee.component';
import {BoeTotalScheduleAmountComponent} from './components/boe-total-schedule-amount/boe-total-schedule-amount.component';
import {BoeScheduleAmountPerLineComponent} from './components/boe-schedule-amount-per-line/boe-schedule-amount-per-line.component';
import {UniqueConsignmentReferenceComponent} from './components/unique-consignment-reference/unique-consignment-reference.component';
import {HawbFlightDetailSectionComponent} from './components/hawb-flight-detail-section/hawb-flight-detail-section.component';
import {HawbForwardFileSectionComponent} from './components/hawb-forward-file-section/hawb-forward-file-section.component';
import {HawbDegroupTransitSectionComponent} from './components/hawb-degroup-transit-section/hawb-degroup-transit-section.component';
import {ContainersSectionComponent} from './components/road-ocean-components/containers-section/containers-section.component';
import {ContainerTypeSectionComponent} from './components/road-ocean-components/container-type-section/container-type-section.component';
import {ContainerSolasSectionComponent} from './components/road-ocean-components/container-solas-section/container-solas-section.component';
import {ContainerSizeSectionComponent} from './components/road-ocean-components/container-size-section/container-size-section.component';
import {MasContainerSizeComponent} from './components/road-ocean-components/mas-container-size/mas-container-size.component';
import {MasContainerTypeComponent} from './components/road-ocean-components/mas-container-type/mas-container-type.component';
import {RunningTotalSectionContainerComponent} from './components/road-ocean-components/running-total-section-container/running-total-section-container.component';
import {SealDetailSectionComponent} from './components/road-ocean-components/seal-detail-section/seal-detail-section.component';
import {ContainerSectionTableComponent} from './components/road-ocean-components/container-section-table/container-section-table.component';
import {DepotOrTerminalComponent} from './components/road-ocean-components/depot-or-terminal/depot-or-terminal.component';
import {TerminalComponent} from './components/road-ocean-components/depot-or-terminal/terminal/terminal.component';
import {RoadSeaDepotComponent} from './components/road-ocean-components/depot-or-terminal/depot/depot.component';
import { RoadStorageDetailsComponent } from './components/road-ocean-components/road-storage-details/road-storage-details.component';
import {VoyageDetailsComponent} from './components/voyage-details/voyage-details.component';
import {RoadOceanlineDetailsComponent} from './components/road-ocean-components/road-oceanline-details/road-oceanline-details.component';

import {TransportStepsNavigationComponent} from './transport-steps-navigation/transport-steps-navigation.component';
import { SupplierModalComponent } from './components/business-entity/supplier-modal/supplier-modal.component';
import {CustomsTraderTypeComponent} from './components/customs-trader-type/customs-trader-type.component';
import { PermitsComponent } from './permits/components/permits.component';
import { DiagnosticsComponent } from './diagnostics/diagnostics.component';
import { BusinessEntitySearchComponent } from './components/business-entity-search/business-entity-search.component';
import { ExportsFileHeaderSectionComponent } from './components/exports-file-header-section/exports-file-header-section.component';
import { MultilineAddressPipe } from './pipes/multiline-address.pipe';
import { BusinessEntityModalComponent } from './components/business-entity/export-consignee-modal/business-entity-modal.component';
import { ExportTransportDocumentComponent } from './components/road-ocean-components/export-transport-document/export-transport-document.component';
import { ExportsTransportStepsNavigationComponent } from './exports-transport-steps-navigation/exports-transport-steps-navigation.component';
import { ExportsInvoiceHeadersComponent } from './components/supplier-invoice/exports-invoice-headers/exports-invoice-headers.component';
import { ExportsSupplierInvoiceTableComponent } from './components/exports-supplier-invoice-table/exports-supplier-invoice-table.component';
import { ExportsGenerateComponent } from './components/boe-generator/exports-generate/exports-generate.component';
import { InvoiceExportConsigneeExporterDetailsComponent } from './components/invoice-export-consignee-exporter-details/invoice-export-consignee-exporter-details.component';
import { ExportFileSearchComponent } from './components/export-file-search/export-file-search.component';
import { ExportBillOfEntryComponent } from './components/export-bill-of-entry/export-bill-of-entry.component';
import { DeferredPaymentsComponent } from './deferred-payments/deferred-payments.component';
import { InvoiceRegisterComponent } from './invoice-register/invoice-register.component';
import { VocReportComponent } from './voc-report/voc-report.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ExportsFinancialsComponent } from './components/financials/exports-financials/exports-financials.component';
import { CrewMaintenanceComponent } from './components/crew-maintenance/crew-maintenance.component';
import { CrewMemberComponent } from './components/crew-member/crew-member.component';
import { ManifestSequenceModalComponent } from './components/manifest-sequence-modal/manifest-sequence-modal.component';
import { ManifestBusinessEntitySearchComponent } from './components/manifest-business-entity-search/manifest-business-entity-search.component';
import { ManifestPortComponent } from './components/manifest-port/manifest-port.component';
import { CreditNoteComponent } from './components/financials/credit-note/credit-note.component';
import { CommercialInvoiceComponent } from './components/financials/commercial-invoice/commercial-invoice.component';
import { PackingListComponent } from './components/packing-list/packing-list.component';
import { VesselsAgentComponent } from './components/vessels-agent/vessels-agent.component';
import { ShipsAgentComponent } from './components/ships-agent/ships-agent.component';
import {ExportRegistrationComponent} from './components/export-registration/export-registration.component';
import {HawbAirlineSectionComponent} from './components/hawb-airline-section/hawb-airline-section.component';
import { ShipsAgentHeaderComponent } from './components/ships-agent-header/ships-agent-header.component';
import { OceanBolComponent } from './components/ocean-bol/ocean-bol.component';
import { ShipsAgentContainersComponent } from './components/ships-agent-containers/ships-agent-containers.component';
import {MarksAndNumbersComponent} from './components/marks-and-numbers/marks-and-numbers.component';
import { ShipsAgentPackagesComponent } from './components/ships-agent-packages/ships-agent-packages.component';
import { ShipsAgentReportsComponent } from './components/ships-agent-reports/ships-agent-reports.component';
import {BookingComponent} from './components/booking/booking.component';
import { RoutingComponent } from './components/routing/routing.component';
import { PackageDimsComponent } from './components/package-dims/package-dims.component';
import { AirWaybillComponent } from './components/mawb/air-waybill.component';
import { CostingComponent } from './components/costing/costing.component';
import { PartsMaintenanceComponent } from './components/parts-maintenance/parts-maintenance.component';
import { PartComponent } from './components/part/part.component';
import { VatPaymentsComponent } from './components/vat-payments/vat-payments.component';
import { CompanyComponent } from './components/company/company.component';
import { ForwardingStepsNavigationComponent } from './components/forwarding-steps-navigation/forwarding-steps-navigation.component';
import { ForwardingFileHeaderSectionComponent } from './components/forwarding-file-header-section/forwarding-file-header-section.component';
import { ShippingOrderComponent } from './components/shipping-order/shipping-order.component';
import { CpcEnquiryComponent } from './components/cpc-enquiry/cpc-enquiry.component';
import { TariffEnquiryComponent } from './components/tariff-enquiry/tariff-enquiry.component';
import { SummaryComponent } from './components/tariff-enquiry/summary/summary.component';
import { DescriptionsComponent } from './components/tariff-enquiry/descriptions/descriptions.component';
import { DependantsComponent } from './components/tariff-enquiry/dependants/dependants.component';
import { TariffHistoryComponent } from './components/tariff-enquiry/tariff-history/tariff-history.component';
import { TariffCalculationsComponent } from './components/tariff-enquiry/tariff-calculations/tariff-calculations.component';
import { CustomsDocumentsEmailComponent } from './components/customs-documents-email/customs-documents-email.component';
import { ReprintInvoicesComponent } from './components/reprint-invoices/reprint-invoices.component';
import { ExportRegistrationReportsComponent } from './components/export-registration-reports/export-registration-reports.component';
import { ImportBondStoreReportsComponent } from './components/import-bond-store-reports/import-bond-store-reports.component';
import { TrackingComponent } from './components/tracking/tracking.component';
import {DA490Component} from './da490/da490.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(COMMON_ROUTES),
        PanelMenuModule,
        SidebarModule,
        ToastModule,
        MessageModule,
        MessagesModule,
        TableModule,
        CheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        TriStateCheckboxModule,
        AutoCompleteModule,
        InputTextModule,
        ButtonModule,
        PanelModule,
        CalendarModule,
        DialogModule,
        DropdownModule,
        OverlayPanelModule,
        DataViewModule,
        ScrollPanelModule,
        TabMenuModule,
        TabViewModule,
        CardModule,
        AccordionModule,
        TreeModule,
        TooltipModule,
        ToolbarModule,
        FileUploadModule,
        InputTextareaModule,
        StepsModule,
        RadioButtonModule,
        SelectButtonModule,
        MenubarModule,
        MenuModule,
        InputMaskModule,
        ProgressSpinnerModule,
        PdfViewerModule,
        ConfirmDialogModule,
        PickListModule,
        ChipsModule
    ],
  declarations: [
    PrimaryNavigationComponent,
    TravelTypesComponent,
    SarsDashboardComponent,
    ReplaceUnderScoresPipe,
    ImporterComponent,
    AddressComponent,
    ImporterDebtorsComponent,
    ClearingAgentComponent,
    RegistrationReferencesSectionComponent,
    CollectOrPrepaidSearchComponent,
    CustomsOfficeComponent,
    DegroupSearchComponent,
    FileIncoTermComponent,
    SundrySearchComponent,
    SequenceModalComponent,
    RebateUserComponent,
    ClearingFileSearchComponent,
    ClearingFileSectionComponent,
    CorrectionComponent,
    HawbSummaryTableComponent,
    CountryOfOriginComponent,
    CurrencyComponent,
    CurrencyNestedComponent,
    BillOfEntryTableComponent,
    BillOfEntryComponent,
    BeforeAndAfterPaymentDetailsComponent,
    BoeMarksNumbersComponent,
    BoePaymentDetailsComponent,
    BoePackageSectionComponent,
    BoePermitsSectionComponent,
    BoeBankDetailsComponent,
    BoeLinesTopSectionComponent,
    BoeLinesComponent,
    BoeLinesTableSectionComponent,
    BoeLineDetailsComponent,
    BoeLineAdditionalInfoComponent,
    BoeLineOriginalBoeComponent,
    BoeLineUnitsQuantityComponent,
    BoeFileSectionComponent,
    BoeLinesTabTariffDescriptionSectionComponent,
    PurchaseTermsComponent,
    DigiBoeLineScheduleAmountComponent,
    InvoiceLinesDefaultsSectionComponent,
    TotalWeightQuantityComponent,
    SupplierInvoiceTableComponent,
    InvoiceSupplierImporterDetailsComponent,
    InvoiceDefaultValuesComponent,
    TradeAgreementComponent,
    SundryChargesComponent,
    ValueBasedChargeComponent,
    TradeAgreementComponent,
    InvoiceLinesTransportDocsComponent,
    InvoiceLinesTransportDocsComponent,
    InvoiceLinesTableComponent,
    InvoiceLinesSectionComponent,
    ConsolidationDetailsSectionComponent,
    ConsolidationDetailsTableComponent,
    InvoiceLinesDutyPaidModalComponent,
    PermitsTableComponent,
    PermitsTableComponent,
    RateBasedChargeComponent,
    ConfirmationSectionComponent,
    SupplierComponent,
    MarksNumbersComponent,
    InvoiceHeadersComponent,
    InvoiceLinesComponent,
    GenerateComponent,
    SundryChargesComponent,
    HawbWeightMeasuresSectionComponent,
    HawbFreightSectionComponent,
    HawbBoeDetailsSectionComponent,
    HawbPortToComponent,
    PortDetailsComponent,
    PurchaseTermsPipe,
    CountryPipe,
    TradeAgreementsPipe,
    PermitNumbersPipe,
    TariffCodePipe,
    BusinessEntityPipe,
    TariffCodePipe,
    CurrencyPipe,
    SearchFilterPipePipe,
    TariffMaintenanceComponent,
    TariffMaintenanceNavigationComponent,
    TariffMainMaintenanceComponent,
    ExchangeRatesComponent,
    TariffMaintenanceDescriptionComponent,
    SelectedTariffSectionComponent,
    TariffMaintenanceTableDescriptionComponent,
    TariffFormulaeComponent,
    TariffFormulaeTableComponent,
    DependentComponent,
    TariffDependentComponent,
    NotificationComponent,
    ClearingInstructionModalComponent,
    FinSupplrinvHeadersComponent,
    FinInvdetailsTotalComponent,
    FinInvdetailsValuesComponent,
    LocalForwarderComponent,
    VesselsAgentComponent,
    DepotComponent,
    GuaranteeTypeComponent,
    WarehouseDetailsComponent,
    RemoverComponent,
    AdditionalScheduleTariffComponent,
    DocumentsComponent,
    LoadingComponent,
    GuarantorComponent,
    ConsigneeComponent,
    CustomsOfficeConsigneeComponent,
    ImporterConsigneeComponent,
    LocalAgentConsigneeComponent,
    WarehouseConsigneeComponent,
    OverseasAgentConsigneeComponent,
    ClearingInstructionImporterConsigneeComponent,
    BoeTotalScheduleAmountComponent,
    BoeScheduleAmountPerLineComponent,
    UniqueConsignmentReferenceComponent,
    TransportStepsNavigationComponent,
    SupplierModalComponent,
    CustomsTraderTypeComponent,
    VoyageDetailsComponent,
    HawbFlightDetailSectionComponent,
    HawbForwardFileSectionComponent,
    HawbDegroupTransitSectionComponent,
    ContainersSectionComponent,
    ContainerTypeSectionComponent,
    ContainerSolasSectionComponent,
    ContainerSizeSectionComponent,
    MasContainerSizeComponent,
    MasContainerTypeComponent,
    RunningTotalSectionContainerComponent,
    SealDetailSectionComponent,
    ContainerSectionTableComponent,
    DepotOrTerminalComponent,
    TerminalComponent,
    RoadSeaDepotComponent,
    RoadStorageDetailsComponent,
    TransportStepsNavigationComponent,
    RoadOceanlineDetailsComponent,
    PermitsComponent,
    DiagnosticsComponent,
    BusinessEntitySearchComponent,
    ExportsFileHeaderSectionComponent,
    MultilineAddressPipe,
    BusinessEntityModalComponent,
    ExportTransportDocumentComponent,
    ExportsTransportStepsNavigationComponent,
    ExportsInvoiceHeadersComponent,
    ExportsSupplierInvoiceTableComponent,
    ExportsGenerateComponent,
    ExportFileSearchComponent,
    ExportBillOfEntryComponent,
    InvoiceExportConsigneeExporterDetailsComponent,
    DeferredPaymentsComponent,
    InvoiceRegisterComponent,
    VocReportComponent,
    DA490Component,
    ExportsFinancialsComponent,
    CrewMaintenanceComponent,
    CrewMemberComponent,
    ManifestSequenceModalComponent,
    ManifestBusinessEntitySearchComponent,
    ManifestPortComponent,
    CreditNoteComponent,
    CommercialInvoiceComponent,
    PackingListComponent,
    ShipsAgentComponent,
    ExportRegistrationComponent,
    HawbAirlineSectionComponent,
    ShipsAgentHeaderComponent,
    OceanBolComponent,
    ShipsAgentContainersComponent,
    MarksAndNumbersComponent,
    ShipsAgentPackagesComponent,
    ShipsAgentReportsComponent,
    BookingComponent,
    RoutingComponent,
    PackageDimsComponent,
    AirWaybillComponent,
    CostingComponent,
    PartsMaintenanceComponent,
    PartComponent,
    VatPaymentsComponent,
    CompanyComponent,
    ForwardingStepsNavigationComponent,
    ForwardingFileHeaderSectionComponent,
    ShippingOrderComponent,
    CpcEnquiryComponent,
    TariffEnquiryComponent,
    SummaryComponent,
    DescriptionsComponent,
    DependantsComponent,
    TariffHistoryComponent,
    TariffCalculationsComponent,
    CustomsDocumentsEmailComponent,
    ReprintInvoicesComponent,
    ExportRegistrationReportsComponent,
    ImportBondStoreReportsComponent,
    TrackingComponent
  ],
  entryComponents: [SundryChargesComponent, InvoiceLinesDutyPaidModalComponent],
    exports: [
        PrimaryNavigationComponent,
        CustomsDocumentsEmailComponent,
        TravelTypesComponent,
        ImporterComponent,
        AddressComponent,
        ImporterDebtorsComponent,
        ClearingAgentComponent,
        RegistrationReferencesSectionComponent,
        CollectOrPrepaidSearchComponent,
        CustomsOfficeComponent,
        DegroupSearchComponent,
        FileIncoTermComponent,
        SundrySearchComponent,
        SequenceModalComponent,
        RebateUserComponent,
        ClearingFileSearchComponent,
        ClearingFileSectionComponent,
        CorrectionComponent,
        HawbSummaryTableComponent,
        CountryOfOriginComponent,
        CurrencyComponent,
        BillOfEntryTableComponent,
        BillOfEntryComponent,
        BeforeAndAfterPaymentDetailsComponent,
        BoeMarksNumbersComponent,
        BoePaymentDetailsComponent,
        BoePackageSectionComponent,
        BoePermitsSectionComponent,
        BoeBankDetailsComponent,
        BoeLinesTopSectionComponent,
        BoeLinesComponent,
        BoeLinesTableSectionComponent,
        BoeLineDetailsComponent,
        BoeLineAdditionalInfoComponent,
        BoeLineOriginalBoeComponent,
        BoeLineUnitsQuantityComponent,
        BoeFileSectionComponent,
        BoeLinesTabTariffDescriptionSectionComponent,
        PurchaseTermsComponent,
        DigiBoeLineScheduleAmountComponent,
        InvoiceLinesDefaultsSectionComponent,
        TotalWeightQuantityComponent,
        SupplierInvoiceTableComponent,
        InvoiceSupplierImporterDetailsComponent,
        InvoiceDefaultValuesComponent,
        InvoiceLinesTableComponent,
        InvoiceLinesSectionComponent,
        ConsolidationDetailsSectionComponent,
        ConsolidationDetailsTableComponent,
        InvoiceLinesDutyPaidModalComponent,
        PermitsTableComponent,
        PermitsTableComponent,
        RateBasedChargeComponent,
        ConfirmationSectionComponent,
        SupplierComponent,
        MarksNumbersComponent,
        InvoiceHeadersComponent,
        InvoiceLinesComponent,
        GenerateComponent,
        SundryChargesComponent,
        PortDetailsComponent,
        HawbWeightMeasuresSectionComponent,
        HawbFreightSectionComponent,
        HawbBoeDetailsSectionComponent,
        HawbPortToComponent,
        NotificationComponent,
        ClearingInstructionModalComponent,
        FinSupplrinvHeadersComponent,
        FinInvdetailsTotalComponent,
        FinInvdetailsValuesComponent,
        LocalForwarderComponent,
        VesselsAgentComponent,
        DepotComponent,
        GuaranteeTypeComponent,
        WarehouseDetailsComponent,
        LoadingComponent,
        ConsigneeComponent,
        TransportStepsNavigationComponent,
        CustomsTraderTypeComponent,
        VoyageDetailsComponent,
        HawbFlightDetailSectionComponent,
        HawbForwardFileSectionComponent,
        HawbDegroupTransitSectionComponent,
        TransportStepsNavigationComponent,
        HawbDegroupTransitSectionComponent,
        ContainersSectionComponent,
        ContainerTypeSectionComponent,
        ContainerSolasSectionComponent,
        ContainerSizeSectionComponent,
        MasContainerSizeComponent,
        MasContainerTypeComponent,
        RunningTotalSectionContainerComponent,
        SealDetailSectionComponent,
        ContainerSectionTableComponent,
        TerminalComponent,
        RoadSeaDepotComponent,
        RoadOceanlineDetailsComponent,
        BusinessEntitySearchComponent,
        ExportsFileHeaderSectionComponent,
        MultilineAddressPipe,
        ExportsTransportStepsNavigationComponent,
        ManifestSequenceModalComponent,
        CrewMemberComponent,
        ManifestBusinessEntitySearchComponent,
        ManifestPortComponent,
        ExportRegistrationComponent,
        MarksAndNumbersComponent,
        HawbAirlineSectionComponent,
        TariffCodePipe,
        CreditNoteComponent,
        CommercialInvoiceComponent,
        PackingListComponent,
        ForwardingStepsNavigationComponent,
        ForwardingFileHeaderSectionComponent,
        ShippingOrderComponent,
        AdditionalScheduleTariffComponent,
        TrackingComponent
    ]
})
export class DigiCommonModule {
}

