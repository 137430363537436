<p-tabView orientation="left" styleClass="stepMenu">
  <p-tabPanel header="Ex Bond Lines" [selected]="true">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <!-- Clearing Form -->
        <div class="ui-g-12 pt-0">
          <digi-exbond-file-section step="lines" (add)="createNewFile()" [canAdd]="true" label="Add Lines">
          </digi-exbond-file-section>
          <div class="ui-g-2" *ngIf="exBondFile.linkToXrw">
            <digi-exbond-file-xrw-search></digi-exbond-file-xrw-search>
          </div>
          <div class="ui-g-2">
            <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text"
                           pInputText
                           [(ngModel)]="mrnFilter"
                           placeholder="Search by MRN">
                </span>
            </div>
          </div>
          <div class="ui-g-2">
            <div class="ui-inputgroup">
                <span class="md-inputfield">
                    <input type="text"
                           pInputText
                           [(ngModel)]="lineFilter"
                           placeholder="Line No:">
                </span>
            </div>
          </div>
          <div class="ui-g-2">
            <p-checkbox binary="true" label="Link to XRW" [(ngModel)]="exBondFile.linkToXrw"></p-checkbox>
          </div>
        </div>
        <div class="ui-g-12 ui-g-nopad">
          <div class="ui-g">
            <div class="ui-g-12">
              <p-table [columns]="cols" [value]="exBondFile.lines" selectionMode="single" columnResizeMode="expand"
                       [(selection)]="selectedLine"
                       (onRowSelect)="selectLine($event.data)" [paginator]="true" [rows]="5">
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th *ngFor="let col of columns">{{col.header}}</th>
                    <th style="width: 4%">
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-exBondLine let-rowIndex="rowIndex">
                  <tr [pSelectableRow]="exBondLine" [pSelectableRowIndex]="rowIndex" [style]="{'height':'38px'}">
                    <td>{{exBondLine.lineNo}}</td>
                    <td>{{exBondLine.partNumber}}</td>
                    <td>{{exBondLine.localCustomsValue}}</td>
                    <td>{{exBondLine.lineQuantity}}</td>
                    <td class="delete">
                      <button pButton type="button" icon="fa fa-times" class="danger"
                              (click)="onRowDelete(rowIndex)" pTooltip="Delete"></button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <ng-container *ngIf="exBondFile.lines && exBondFile.lines.length">
              <div class="ui-g-8">
                <p-panel header="Previous MRN Details">
                  <div class="ui-fluid">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <div class="ui-g form-group">
                          <div class="ui-g-6 ui-md-6">
                          <span class="md-inputfield">
                            <input type="text" pInputText disabled [ngModel]="selectedLine?.previousBoeDetails?.customsBOENo"
                                   required=true>
                              <label>MRN</label>
                          </span>
                          </div>
                          <div class="ui-g-6 ui-md-6">
                          <span class="md-inputfield">
                            <input type="text" pInputText disabled [ngModel]="selectedLine?.previousBoeDetails?.date"
                                   required=true>
                              <label>Customs Date</label>
                          </span>
                          </div>
                          <div class="ui-g-6 ui-md-6">
                          <span class="md-inputfield">
                            <input type="text" pInputText disabled [ngModel]="selectedLine?.partNumber"
                                   required=true>
                              <label>Part Number</label>
                          </span>
                          </div>
                          <div class="ui-g-6 ui-md-6">
                          <span class="md-inputfield">
                            <input type="text" pInputText disabled [ngModel]="selectedLine?.previousBoeDetails?.cpc"
                                   required=true>
                              <label>Previous Procedure Code</label>
                          </span>
                          </div>
                          <div class="ui-g-6 ui-md-6">
                          <span class="md-inputfield">
                            <input type="text" pInputText disabled [ngModel]="selectedLine?.packType?.code"
                                   required=true>
                              <label>Pack Type</label>
                          </span>
                          </div>
                          <div class="ui-g-6 ui-md-6">
                          <span class="md-inputfield">
                            <input type="text" pInputText disabled [ngModel]="selectedLine?.previousBoeDetails?.noOfPacks"
                                   required=true>
                              <label>Available Packs</label>
                          </span>
                          </div>
                          <div class="ui-g-6 ui-md-6">
                          <span class="md-inputfield">
                            <input type="text" pInputText disabled [ngModel]="selectedLine?.itemsPerPack"
                                   required=true>
                              <label>Parts Per Pack</label>
                          </span>
                          </div>
                          <div class="ui-g-6 ui-md-6">
                          <span class="md-inputfield">
                            <input type="text" pInputText disabled [ngModel]="selectedLine?.previousBoeDetails?.lineQuantity"
                                   required=true>
                              <label>Quantity</label>
                          </span>
                          </div>
                          <div class="ui-g-6 ui-md-6">
                          <span class="md-inputfield">
                            <input type="text" pInputText disabled [ngModel]="selectedLine?.previousBoeDetails?.localCustomsValue"
                                   required=true>
                              <label>Value</label>
                          </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
              <div class="ui-g-4 ui-md-4">
                <p-panel header="Package Details">
                  <div class="ui-fluid">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <div class="ui-g form-group">
                          <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <input type="text" pInputText disabled [(ngModel)]="selectedLine.noOfPacks"
                                     required=true>
                                <label>No.of Packs</label>
                            </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <div class="ui-inputgroup">
                              <span class="ui-float-label">
                                <p-autoComplete id="packType" [(ngModel)]="selectedLine.packType"
                                                field="label"
                                                [dropdown]="true"
                                                [suggestions]="filteredPackTypes"
                                                (completeMethod)="searchPackTypes($event)"
                                                (onSelect)="selectPackType($event)"
                                                [autoHighlight]="true" minLength="1"
                                                [disabled]="true"
                                                required="true">
                                  <ng-template let-option pTemplate="item">
                                    <div>{{option.code}} :{{option.description}}</div>
                                  </ng-template>
                                </p-autoComplete>
                              <label for="packType">Pack Type</label>
                            </span>
                            </div>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <input type="text" pInputText disabled [(ngModel)]="selectedLine.localCustomsValue"
                                     required=true>
                                <label>Line Value</label>
                            </span>
                          </div>
                          <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                <input type="text"
                                       pInputText
                                       disabled
                                       [(ngModel)]="selectedLine.lineQuantity"
                                       tooltipPosition="top"
                                       required="true">
                                  <label>Quantity</label>
                                </span>
                                <span
                                  class="ui-inputgroup-addon">{{selectedLine.tariffCode.formula?.statisticalUOM}}</span>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </p-panel>
              </div>
              <div class="ui-g-8 ui-md-8">
                <digi-additional-schedule-tariff [selectedAdditionalScheduleTariffs]="additionalScheduleTariffs"
                                                 [clearingInstruction]="exBondFile.clearingInstructions[0]" [tariffCode]="selectedLine.tariffCode"
                                                 (selectedAdditionalScheduleTariffsChange)="updateAdditionalSchedules($event)"></digi-additional-schedule-tariff>
              </div>
              <div class="ui-g-12 ui-md-12">
                <div class="ui-g-4 ui-md-4">
                  <p-panel header="Tariff Details">
                    <div class="ui-fluid">
                      <div class="ui-g">
                        <div class="ui-g-12">
                          <div class="ui-g form-group">
                            <div class="ui-g-12 ui-md-12">
                              <span class="md-inputfield">
                                  <div class="ui-inputgroup">
                                    <span class="ui-float-label">
                                        <p-autoComplete id="tariff" [(ngModel)]="selectedLine.tariffCode" field="code"
                                                        [suggestions]="filteredTariffCodes"
                                                        (completeMethod)="searchTariffCode($event)"
                                                        [autoHighlight]="true"
                                                        (onSelect)="selectTariffCode($event)"
                                                        minLength="3"
                                                        required="true">
                                          <ng-template let-option pTemplate="item">
                                            <div>{{option.code}}: {{option.tariffSDesc}}</div>
                                          </ng-template>
                                        </p-autoComplete>
                                        <label for="tariff">Tariff Code</label>
                                    </span>
                                    <span class="ui-inputgroup-addon">{{selectedLine.tariffCode.checkDigit}}</span>
                                  </div>
                                 <div *ngIf="!selectedLine.tariffCode.code" class="ui-message ui-messages-error ui-corner-all">
                                    This field is required
                                </div>
                              </span>
                            </div>
                            <div class="ui-g-12 ui-md-12">
                          <textarea pInputTextarea [ngModel]="selectedLine?.tariffCode?.description" [rows]="3" [cols]="50"
                                    placeholder="Description"
                                    style="resize: none">
                          </textarea>
                            </div>
                            <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText [ngModel]="selectedLine?.tariffCode?.formula?.statisticalUOM">
                                  <label>Statistical UOM</label>
                                </span>
                            </div>
                          </span>
                            </div>
                            <div class="ui-g-12 ui-md-12">
                          <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="md-inputfield">
                                  <input type="text" pInputText [ngModel]="selectedLine?.tariffCode?.formula?.description">
                                  <label>Formula</label>
                                </span>
                            </div>
                          </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </p-panel>
                </div>
                <div class="ui-g-4 ui-md-4">
                  <p-panel header="Additional UOMs">
                    <p-table [columns]="uomCols" [value]="selectedLine.additionalUoms"
                             selectionMode="single"
                             dataKey="code">
                      <ng-template pTemplate="colgroup" let-columns>
                        <colgroup>
                          <col *ngFor="let col of columns" [style.width]="col.width"/>
                        </colgroup>
                      </ng-template>
                      <ng-template pTemplate="header" let-columns>
                        <tr>
                          <th *ngFor="let col of columns">{{ col.header }}</th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                        <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex">
                          <td class="ui-resizable-column">{{ rowData?.uomCode }}</td>
                          <td>
                            <input type="text" style="height: 20px;" pInputText [(ngModel)]="rowData.value"/>
                          </td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </p-panel>
                  <div class="ui-g form-group">
                    <div class="ui-g-12 ui-md-12">
                      <p-checkbox binary="true" label="Customs VAT Required" [(ngModel)]="selectedLine.vatRequired"></p-checkbox>
                    </div>
                  </div>
                </div>
                <div class="ui-g-4 ui-md-4">
                  <p-panel header="Origin Details">
                    <div class="ui-fluid">
                      <div class="ui-g">
                        <div class="ui-g-12">
                          <div class="ui-g form-group">
                            <digi-country-of-origin label="Country of Destination" class="ui-g-12 ui-md-12" [country]="selectedLine?.countryOfOrigin"
                                                    (onSelect)="selectCountry($event)"></digi-country-of-origin>
                            <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <div class="ui-inputgroup">
                                <span class="ui-float-label">
                                  <p-autoComplete id="tradeAgreement" [(ngModel)]="selectedLine.tradeAgreement" field="code"
                                                  [suggestions]="filteredTradeAgreements"
                                                  (completeMethod)="searchTradeAgreement($event)"
                                                  [autoHighlight]="true" (onSelect)="selectTradeAgreement($event)" minLength="2"
                                                  [dropdown]="true"
                                                  required=true>
                                      <ng-template let-option pTemplate="item">
                                        <div>{{option.code}}</div>
                                      </ng-template>
                                  </p-autoComplete>
                                  <label for="tradeAgreement">Trade Agreement</label>
                                </span>
                              </div>
                            </span>
                            </div>
                            <div class="ui-g-12 ui-md-12">
                            <span class="md-inputfield">
                              <input type="text" pInputText [(ngModel)]="selectedLine.rooNumber"
                                     required=true>
                                <label>R.O.O No.</label>
                            </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </p-panel>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  </p-tabPanel>
  <p-tabPanel header="Mrks,Nmbrs">
    <ng-template pTemplate="content">
      <div class="ui-g-12 ui-g-nopad">
        <digi-marks-numbers [filetype]="'exBond'">
          <digi-exbond-file-section step="lines" (add)="createNewFile()" [canAdd]="false" label="Add Lines">
          </digi-exbond-file-section>
        </digi-marks-numbers>
      </div>
    </ng-template>
  </p-tabPanel>
</p-tabView>
<p-dialog header="Bills Of Entry"
          [(visible)]="showDialog"
          [modal]="true"
          [responsive]="true"
          [minY]="70"
          [minWidth]="200"
          [baseZIndex]="10000"
          [contentStyle]="{'height':'600px','width':'80vw'}"
          (onHide)="onClose()">
  <div class="ui-fluid">
    <div class="ui-g">
      <div class="ui-g-12">
        <p-table [columns]="boeLinesCols" [value]="lines"
                 [(selection)]="selectedLines"
                 selectionMode="multiple"
                 [resizableColumns]="true"
                 [paginator]="true" [rows]="5">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="width: 5%"> <p-tableHeaderCheckbox></p-tableHeaderCheckbox>Select All</th>
              <th style="color: #000" *ngFor="let col of columns" [style.width]="col.width">{{col.header}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
            <tr [pSelectableRow]="rowData" [pSelectableRowIndex]="rowIndex" [pSelectableRowDisabled]="rowData?.previousBoeDetails?.remainingPacks <= 0">
              <td>
                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
              </td>
              <td>
                {{rowData?.lineNo}}
              </td>
              <td>
                {{rowData?.previousBoeDetails?.customsBOENo}}
              </td>
              <td>
                {{rowData?.previousBoeDetails?.cpc}}
              </td>
              <td>
                {{rowData?.previousBoeDetails?.sequenceNumber}}
              </td>
              <td>
                {{rowData?.previousBoeDetails?.date}}
              </td>
              <td>
                {{rowData?.partNumber}}
              </td>
              <td>
                {{rowData?.previousBoeDetails?.remainingPacks}}
              </td>
              <td>
                <input pInputText [(ngModel)]="rowData.noOfPacks" (ngModelChange)="recalculateValues(rowData)">
              </td>
              <td>
                {{rowData?.packType?.code}}
              </td>
              <td>
                <input pInputText [(ngModel)]="rowData.localCustomsValue">
              </td>
              <td>
                <input pInputText [(ngModel)]="rowData.lineQuantity">
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-close" (click)="createExbondLines()"
            label="Confirm"
            class="ui-button-secondary"></button>
    <button type="button" pButton icon="pi pi-close" (click)="onClose()"
            label="Cancel"
            class="ui-button-secondary"></button>
    <button type="button" pButton icon="pi pi-close" (click)="deselectBoes()"
            label="Deselect All"
            class="ui-button-secondary"></button>
  </p-footer>
</p-dialog>
